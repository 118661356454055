import React, { useState, useEffect } from "react";
import dateFormat from "dateformat";

import AddDate from "components/project/AddDate";
import AddTime from "components/project/AddTime";
import EditDate from "components/widgets/EditDate";
import EditText from "components/widgets/EditText";
import BoxWrapper from "components/wrappers/BoxWrapper";

//HOOKS
import useGlobal from "hooks/useGlobal";
import API from "handlers/API";

// MUI
import {
   Box,
   Button,
   TextField,
   Modal,
   Typography,
   Grid,
   Stack,
} from "@mui/material";

import { FileCopy, Close } from "@mui/icons-material";

import Delete from "images/icon-delete.svg";
import Add from "images/icon-add.svg";

// MODAL
const modalStyle = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 400,
   bgcolor: "background.paper",
   border: "2px solid #000",
   boxShadow: 24,
   textAlign: "center",
   p: 4,
};

export default function Schedules({
   projectId,
   pk,
   allProjectDates,
   changeTab,
   scheduleTitle,
   scheduleDate,
   refresh,
}) {
   const model = "schedules";

   const [
      schedule,
      all,
      { getAll, get, updateModelData, add, remove },
      lastAdded,
      lastUpdated,
   ] = useGlobal();

   // MODAL
   const [open, setOpen] = useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   useEffect(() => {
      if (pk) {
         getAll(model, pk);
      }
   }, []);

   const handleDeleteDate = (pk) => {
      remove("projectdates", pk, projectId).then(() => {
         refresh();
      });
   };

   const handleAddDate = (date) => {
      add("projectdates", projectId, "date", date).then(() => {
         refresh();
      });
   };

   const handleUpdateTime = (id, time) => {
      saveDateChange(id, "time", time);
   };

   const saveChange = (value, custom) => {
      let data = {
         ...custom,
         value: value,
      };

      updateModelData(data)
         .then((response) => getAll(model, pk))
         .then(() => {
            if (data.model === "projectdates") {
               refresh();
            }
         });
   };

   const saveDateChange = (id, name, value) => {
      switch (name) {
         case "time":
            value = dateFormat(value, "HH:MM:ss");
            break;
         default:
            return true;
      }

      let data = {
         refreshtype: "list",
         model: model,
         pk: id,
         name: name,
         value: value,
      };

      updateModelData(data).then((response) => getAll(model, pk));
   };

   const handleKeyPress = (e) => {
      if (e.key === "Enter") {
         e.target.blur();
      }
   };

   return (
      <Box sx={{ mt: 4 }}>
         <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            spacing={1}
            sx={{
               mt: 6,
               mb: 3,
            }}
         >
            <Typography
               variant='h2'
               component='div'
               sx={{
                  m: 0,
                  whiteSpace: "nowrap",
               }}
            >
               Schedule
            </Typography>
            <Box sx={{ width: "100%" }}>
               <AddDate callback={handleAddDate} />
            </Box>
         </Stack>

         <BoxWrapper>
            <Stack
               direction='row'
               alignItems='center'
               justifyContent='flex-start'
               spacing={1}
               sx={{ mb: 3 }}
            >
               <Stack spacing={1} sx={{ whiteSpace: "nowrap", width: "100%" }}>
                  <Typography variant='h4' component='div' sx={{ m: 0 }}>
                     <EditDate
                        key={scheduleDate}
                        value={scheduleDate}
                        width={400}
                        callback={(value) =>
                           saveChange(value, {
                              model: "projectdates",
                              pk: pk,
                              name: "date",
                           })
                        }
                     />
                  </Typography>

                  <Typography variant='h4' component='div' sx={{ m: 0 }}>
                     <EditText
                        key={scheduleTitle}
                        value={scheduleTitle}
                        width={300}
                        callback={(value) =>
                           saveChange(value, {
                              model: "projectdates",
                              pk: pk,
                              name: "description",
                           })
                        }
                     />
                  </Typography>
               </Stack>
               <Box>
                  <Button
                     variant='contained'
                     color='secondary'
                     startIcon={<FileCopy />}
                     onClick={handleOpen}
                     sx={{ whiteSpace: "nowrap" }}
                  >
                     DUPLICATE SCHEDULE
                  </Button>
               </Box>
            </Stack>

            {all.schedules &&
               all.schedules.map((row, i) => (
                  <Grid
                     container
                     spacing={1.5}
                     sx={{ mb: 1.5 }}
                     key={"scheduleItem" + row.id}
                     justifyContent='flex-start'
                     alignItems='stretch'
                  >
                     <Grid item xs={2} sx={{ position: "relative" }}>
                        <AddTime
                           id={row.id}
                           value={row.time}
                           callback={handleUpdateTime}
                        />
                     </Grid>
                     <Grid item xs={4}>
                        <TextField
                           size='small'
                           index={i}
                           placeholder='Title'
                           name='title'
                           defaultValue={row.title}
                           onBlur={(e) =>
                              saveChange(e.target.value, {
                                 model: model,
                                 pk: row.id,
                                 name: "title",
                              })
                           }
                           onKeyPress={handleKeyPress}
                           fullWidth
                        />
                     </Grid>
                     <Grid item xs={5} sx={{ width: "100%" }}>
                        <TextField
                           size='small'
                           index={i}
                           placeholder='Description'
                           name='description'
                           defaultValue={row.description}
                           onBlur={(e) =>
                              saveChange(e.target.value, {
                                 model: model,
                                 pk: row.id,
                                 name: "description",
                              })
                           }
                           onKeyPress={handleKeyPress}
                           fullWidth
                        />
                     </Grid>
                     <Grid item xs={1}>
                        <Button
                           onClick={() => {
                              remove(model, row.id, pk);
                           }}
                           sx={{ height: "100%" }}
                           fullWidth
                        >
                           <img src={Delete} />
                        </Button>
                     </Grid>
                  </Grid>
               ))}

            <Stack
               direction='row'
               alignItems='flex-end'
               justifyContent='flex-start'
               spacing={1}
               sx={{ mb: 3 }}
            >
               <Box sx={{ width: "100%" }}>
                  <Button
                     onClick={() => {
                        if (all.schedules.length > 0) {
                           add(
                              model,
                              pk,
                              "time",
                              all.schedules[all.schedules.length - 1].time
                           );
                        } else {
                           add(model, pk, "time", "08:00:00");
                        }
                     }}
                     sx={{ color: "rgba(255,255,255,.4)" }}
                  >
                     <img src={Add} style={{ marginRight: "2em" }} />
                     ADD SCHEDULE ITEM
                  </Button>
               </Box>

               <Button
                  variant='outlined'
                  color='error'
                  sx={{ color: "#fff", py: 1.5, px: 3, whiteSpace: "nowrap" }}
                  onClick={() => handleDeleteDate(pk)}
               >
                  <img src={Delete} style={{ marginRight: "2em" }} />
                  DELETE DAY
               </Button>
            </Stack>
         </BoxWrapper>

         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
         >
            <Box sx={modalStyle}>
               <Grid container justifyContent='flex-end'>
                  <Button size='small' onClick={handleClose}>
                     <Close />
                  </Button>
               </Grid>

               <Typography id='modal-modal-title' variant='h6' component='h2'>
                  Copy Schedule
               </Typography>
               <Typography id='modal-modal-title' variant='i' component='i'>
                  Overwrites schedule on selected date
               </Typography>
               <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                  {allProjectDates.map((row, i) => (
                     <>
                        {row.id !== pk && (
                           <Button
                              variant='outlined'
                              sx={{ mb: 2, mx: "auto", display: "block" }}
                              onClick={() =>
                                 API.get(
                                    "/api/schedules/overwrite/" +
                                       pk +
                                       "/" +
                                       row.id
                                 ).then((res) => {
                                    changeTab(i);
                                 })
                              }
                           >
                              {dateFormat(row.date, "shortDate", true)}:{" "}
                              {row.description}
                           </Button>
                        )}
                     </>
                  ))}
               </Typography>
            </Box>
         </Modal>
      </Box>
   );
}
