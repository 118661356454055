import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import dateFormat from "dateformat";

//HOOKS
import useGlobal from "hooks/useGlobal";

import BoxWrapper from "components/wrappers/BoxWrapper";

import Invoices from "pages/Invoices";
import Reporting from "pages/Reporting";

import PersonDetails from "components/people/PersonDetails";

import EditPersonRoles from "components/people/EditPersonRoles";
import PersonImage from "components/people/PersonImage";

// MUI
import {
   Box,
   Chip,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Select,
   TextField,
   Typography,
   Grid,
   Stack,
} from "@mui/material";

import { Close, Delete } from "@mui/icons-material";

import { useTheme } from "@mui/material/styles";

export default function Person() {
   const { personId } = useParams();
   const theme = useTheme();

   const model = "people";

   const [person, allpeople, { getAll, get, updateModelData, add, remove }] =
      useGlobal();

   const [open, setOpen] = useState(true);
   const [activeTab, setActiveTab] = useState("schedule");

   const saveChange = (event) => {
      let name = event.target.getAttribute("name");
      let value = event.target.value;
      let data = { model: model, pk: person.id, name: name, value: value };

      updateModelData(data);
   };

   const refresh = () => {
      get(model, personId);
   };

   const handleKeyPress = (e) => {
      if (e.key === "Enter") {
         e.target.blur();
      }
   };

   useEffect(() => {
      if (personId) {
         get(model, personId);
      }
   }, [personId]);

   useEffect(() => {
      console.log(person);
   }, [person]);

   return (
      <>
         {Object.keys(person).length > 0 && (
            <>
               <Grid container alignItems='flex-start' spacing={3}>
                  <Grid item xs={12} sm={3}>
                     <PersonImage personId={personId} />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                     <BoxWrapper
                        edit
                        transparent
                        childComponent={
                           <PersonDetails person={person} refresh={refresh} />
                        }
                     />
                  </Grid>
               </Grid>

               <Grid container alignItems='flex-start' spacing={4}>
                  <Grid item xs={12}>
                     {activeTab === "schedule" && (
                        <BoxWrapper
                           title='Schedule'
                           titleInset
                           padding={0}
                           height={600}
                        >
                           <TableContainer>
                              <Table layout='childTable'>
                                 <TableHead>
                                    <TableRow>
                                       <TableCell>Project Name</TableCell>
                                       <TableCell>Roles</TableCell>
                                       <TableCell>Dates</TableCell>
                                    </TableRow>
                                 </TableHead>
                                 <TableBody>
                                    {person.projectrolepeople &&
                                       person.projectrolepeople.map(
                                          (row, i) => (
                                             <TableRow
                                                key={"schedule" + row.id}
                                                className='listRow'
                                             >
                                                <TableCell>
                                                   {
                                                      row.projectrole.project
                                                         .project
                                                   }
                                                </TableCell>
                                                <TableCell>
                                                   {row.projectrole.role.title}
                                                </TableCell>
                                                <TableCell>
                                                   {row.projectpeopledates &&
                                                      row.projectpeopledates.map(
                                                         (dates, i) => (
                                                            <Grid
                                                               container
                                                               key={
                                                                  "date" +
                                                                  i +
                                                                  "row" +
                                                                  dates.id
                                                               }
                                                            >
                                                               <Grid
                                                                  item
                                                                  xs={5}
                                                               >
                                                                  <b>
                                                                     {
                                                                        dates
                                                                           .projectdate
                                                                           .description
                                                                     }
                                                                     {!dates
                                                                        .projectdate
                                                                        .description &&
                                                                        "--"}
                                                                  </b>
                                                               </Grid>
                                                               <Grid
                                                                  item
                                                                  xs={7}
                                                               >
                                                                  {dates
                                                                     .projectdate
                                                                     .date &&
                                                                     dateFormat(
                                                                        new Date(
                                                                           dates
                                                                              .projectdate
                                                                              .date +
                                                                              "T" +
                                                                              dates
                                                                                 .projectdate
                                                                                 .starttime
                                                                        ),
                                                                        "ddd, mmm dd, yyyy h:MMTT"
                                                                     )}
                                                                  {!dates
                                                                     .projectdate
                                                                     .date &&
                                                                     "Date TBD"}
                                                               </Grid>
                                                            </Grid>
                                                         )
                                                      )}
                                                </TableCell>
                                             </TableRow>
                                          )
                                       )}
                                 </TableBody>
                              </Table>
                           </TableContainer>
                        </BoxWrapper>
                     )}

                     {activeTab === "invoices" && <Invoices id={person.id} />}

                     {activeTab === "reporting" && <Reporting id={person.id} />}
                  </Grid>

                  <Grid item xs={5}>
                     <BoxWrapper>
                        {person && (
                           <EditPersonRoles
                              personId={personId}
                              refresh={refresh}
                           />
                        )}

                        {/* RATES */}
                        <Box sx={{ mt: 4 }}>
                           <Typography variant='h2' component='div'>
                              Rates
                           </Typography>
                        </Box>
                        <Stack spacing={2}>
                           <TextField
                              variant='outlined'
                              margin='none'
                              fullWidth
                              label='Day Rate'
                              name='dayrate'
                              defaultValue={person.dayrate}
                              onBlur={saveChange}
                              onKeyPress={handleKeyPress}
                           />
                           <TextField
                              variant='outlined'
                              margin='none'
                              fullWidth
                              label='Day Rate Cost'
                              name='dayratecost'
                              defaultValue={person.dayratecost}
                              onBlur={saveChange}
                              onKeyPress={handleKeyPress}
                           />
                           <TextField
                              variant='outlined'
                              margin='none'
                              fullWidth
                              label='Hourly Rate'
                              name='hourlyrate'
                              defaultValue={person.hourlyrate}
                              onBlur={saveChange}
                              onKeyPress={handleKeyPress}
                           />
                           <TextField
                              variant='outlined'
                              margin='none'
                              fullWidth
                              label='Hourly Rate Cost'
                              name='hourlyratecost'
                              defaultValue={person.hourlyratecost}
                              onBlur={saveChange}
                              onKeyPress={handleKeyPress}
                           />
                        </Stack>

                        {/* TRAVEL */}
                        <Box sx={{ mt: 4 }}>
                           <Typography variant='h2' component='div'>
                              Travel
                           </Typography>
                        </Box>

                        <Stack spacing={2}>
                           <TextField
                              variant='outlined'
                              margin='none'
                              fullWidth
                              label='Drivers License Number'
                              name='driverslicense'
                              defaultValue={person.driverslicense}
                              onBlur={saveChange}
                              onKeyPress={handleKeyPress}
                           />
                           <TextField
                              variant='outlined'
                              margin='none'
                              fullWidth
                              label='Passport Number'
                              name='passport'
                              defaultValue={person.passport}
                              onBlur={saveChange}
                              onKeyPress={handleKeyPress}
                           />
                           <TextField
                              variant='outlined'
                              margin='none'
                              fullWidth
                              label='Aisle / Window'
                              name='seatpreference'
                              defaultValue={person.seatpreference}
                              onBlur={saveChange}
                              onKeyPress={handleKeyPress}
                           />
                           <TextField
                              variant='outlined'
                              margin='none'
                              fullWidth
                              label='Alaska Air'
                              name='airline'
                              defaultValue={person.airline}
                              onBlur={saveChange}
                              onKeyPress={handleKeyPress}
                           />
                           <TextField
                              variant='outlined'
                              margin='none'
                              fullWidth
                              label='Frequent Flyer Number'
                              name='frequentflyernumber'
                              defaultValue={person.frequentflyernumber}
                              onBlur={saveChange}
                              onKeyPress={handleKeyPress}
                           />
                        </Stack>

                        {/* EMERGENCY CONTACT */}
                        <Box sx={{ mt: 4 }}>
                           <Typography variant='h2' component='div'>
                              Emergency Contact
                           </Typography>
                        </Box>

                        <TextField
                           variant='outlined'
                           margin='none'
                           fullWidth
                           label='Name / Phone'
                           name='emergencycontact'
                           defaultValue={person.emergencycontact}
                           onBlur={saveChange}
                           onKeyPress={handleKeyPress}
                        />
                        {/* MISC */}
                        <Box sx={{ mt: 4 }}>
                           <Typography variant='h2' component='div'>
                              Misc
                           </Typography>
                        </Box>
                        <Stack spacing={2}>
                           <TextField
                              variant='outlined'
                              margin='none'
                              fullWidth
                              label='Shirt Size'
                              name='shirtsize'
                              defaultValue={person.shirtsize}
                              onBlur={saveChange}
                              onKeyPress={handleKeyPress}
                           />
                           <TextField
                              variant='outlined'
                              margin='none'
                              fullWidth
                              label='Pronouns'
                              name='pronoun'
                              defaultValue={person.pronoun}
                              onBlur={saveChange}
                              onKeyPress={handleKeyPress}
                           />
                           <TextField
                              variant='outlined'
                              margin='none'
                              fullWidth
                              label='Diversity'
                              name='diversity'
                              defaultValue={person.diversity}
                              onBlur={saveChange}
                              onKeyPress={handleKeyPress}
                           />
                           <TextField
                              variant='outlined'
                              margin='none'
                              fullWidth
                              label='Preferred'
                              name='preffered'
                              defaultValue={person.preferred}
                              onBlur={saveChange}
                              onKeyPress={handleKeyPress}
                           />
                        </Stack>
                     </BoxWrapper>
                  </Grid>
                  <Grid item xs={7}>
                     <Box sx={{ ...theme.boxStyleParent }}>
                        {/* OUT OF OFFICE */}
                        <Box sx={{ pt: 2 }}>
                           <Typography variant='h2' component='h2'>
                              Out of Office
                           </Typography>
                        </Box>
                     </Box>

                     <Box sx={{ ...theme.boxStyleParent, mt: 4 }}>
                        {/* DOCUMENTS */}
                        <Box sx={{ pt: 2 }}>
                           <Typography variant='h2' component='h2'>
                              Documents
                           </Typography>
                        </Box>
                        SELECT TYPE
                        <br />
                        <Select>
                           <option value=''>--</option>
                        </Select>
                     </Box>
                  </Grid>
               </Grid>
            </>
         )}
      </>
   );
}
