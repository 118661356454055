import React, { useEffect } from "react";
import FadeIn from "components/wrappers/FadeIn";

//HOOKS
import useGlobal from "hooks/useGlobal";

// MUI
import { Button, Stack, TextField } from "@mui/material";

import { useTheme } from "@mui/material/styles";

import ROSIcon from "images/icon-ros.svg";
import VODIcon from "images/icon-vod.svg";
import SizzleIcon from "images/icon-sizzle.svg";
import ScriptIcon from "images/icon-script.svg";

export default function OverviewLinks({ project, editMode, refresh }) {
   const theme = useTheme();

   const [
      programs,
      all,
      { getAll, get, updateModelData, add, remove, clearParent, find },
   ] = useGlobal();

   useEffect(() => {}, []);

   const saveChange = (event, custom) => {
      let data = {
         ...custom,
         value: event.target.value,
      };

      updateModelData(data).then((response) => refresh());
   };

   const handleKeyPress = (e) => {
      if (e.key === "Enter") {
         e.target.blur();
      }
   };

   return (
      <>
         {editMode && (
            <FadeIn>
               <Stack spacing={2}>
                  <TextField
                     variant='outlined'
                     fullWidth
                     label='Run of Show Link'
                     defaultValue={project.roslink ? project.roslink : ""}
                     onBlur={(e) =>
                        saveChange(e, {
                           model: "projects",
                           pk: project.id,
                           name: "roslink",
                        })
                     }
                     onKeyPress={handleKeyPress}
                  />

                  <TextField
                     variant='outlined'
                     fullWidth
                     label='Video Link'
                     defaultValue={project.vodlink ? project.vodlink : ""}
                     onBlur={(e) =>
                        saveChange(e, {
                           model: "projects",
                           pk: project.id,
                           name: "vodlink",
                        })
                     }
                     onKeyPress={handleKeyPress}
                  />
                  <TextField
                     variant='outlined'
                     fullWidth
                     label='Sizzle Link'
                     defaultValue={project.sizzlelink ? project.sizzlelink : ""}
                     onBlur={(e) =>
                        saveChange(e, {
                           model: "projects",
                           pk: project.id,
                           name: "sizzlelink",
                        })
                     }
                     onKeyPress={handleKeyPress}
                  />

                  <TextField
                     variant='outlined'
                     fullWidth
                     label='Script Link'
                     defaultValue={project.scriptlink ? project.scriptlink : ""}
                     onBlur={(e) =>
                        saveChange(e, {
                           model: "projects",
                           pk: project.id,
                           name: "scriptlink",
                        })
                     }
                     onKeyPress={handleKeyPress}
                  />
               </Stack>
            </FadeIn>
         )}

         {!editMode && (
            <FadeIn>
               <Stack>
                  {project.roslink && (
                     <Button
                        variant='linkicon'
                        size='large'
                        href={project.roslink}
                     >
                        <img src={ROSIcon} /> Run of Show
                     </Button>
                  )}
                  {project.vodlink && (
                     <Button
                        variant='linkicon'
                        size='large'
                        href={project.vodlink}
                     >
                        <img src={VODIcon} /> Video on Demand
                     </Button>
                  )}
                  {project.sizzlelink && (
                     <Button
                        variant='linkicon'
                        size='large'
                        href={project.sizzlelink}
                     >
                        <img src={SizzleIcon} /> Sizzle
                     </Button>
                  )}
                  {project.scriptlink && (
                     <Button
                        variant='linkicon'
                        size='large'
                        href={project.scriptlink}
                     >
                        <img src={ScriptIcon} /> Script
                     </Button>
                  )}
               </Stack>
            </FadeIn>
         )}
      </>
   );
}
