import React, { useEffect } from "react";

import FadeIn from "components/wrappers/FadeIn";

//HOOKS
import useGlobal from "hooks/useGlobal";

// MUI
import { Button, Box, Typography } from "@mui/material";

import { useTheme } from "@mui/material/styles";

export default function OverviewLogo({ project, editMode, refresh }) {
   const theme = useTheme();

   const [
      programs,
      all,
      { getAll, get, updateModelData, add, remove, clearParent, find },
   ] = useGlobal();

   useEffect(() => {}, []);

   const saveChange = (event, custom) => {
      let data = {
         ...custom,
         value: event.target.value,
      };

      updateModelData(data).then((response) => refresh());
   };

   const handleKeyPress = (e) => {
      if (e.key === "Enter") {
         e.target.blur();
      }
   };

   return (
      <>
         {editMode && <FadeIn>Logo editor here TBD</FadeIn>}

         {!editMode && (
            <FadeIn>
               <Typography variant='h5' component='div'>
                  LOGO
               </Typography>

               {project.company && (
                  <Box sx={{ my: 2, textAlign: "center", fontStyle: "italic" }}>
                     {project.company.logo && <>Logo Here</>}
                     {!project.company.logo && <>No Logo</>}
                  </Box>
               )}

               <Button variant='contained' color='secondary'>
                  Download
               </Button>
            </FadeIn>
         )}
      </>
   );
}
