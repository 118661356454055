import React, { useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// MUI
import { Box, Button, Stack } from "@mui/material";

export default function AddDate({ callback }) {
   const [startDate, setStartDate] = useState(new Date());
   const [isOpen, setIsOpen] = useState(false);
   const handleChange = (e) => {
      setIsOpen(!isOpen);
      setStartDate(e);
      callback(e);
   };
   const handleClick = (e) => {
      e.preventDefault();
      setIsOpen(!isOpen);
   };
   return (
      <Stack alignItems='flex-end' sx={{ position: "relative" }}>
         <Button variant='contained' color='success' onClick={handleClick}>
            ADD DAY
         </Button>
         <Box sx={{ position: "absolute", zIndex: 1000, top: 40 }}>
            {isOpen && (
               <DatePicker
                  popperPlacement='top-end'
                  popperModifiers={[
                     {
                        name: "offset",
                        options: {
                           offset: [5, 10],
                        },
                     },
                     {
                        name: "preventOverflow",
                        options: {
                           rootBoundary: "viewport",
                           tether: false,
                           altAxis: true,
                        },
                     },
                  ]}
                  selected={startDate}
                  onChange={handleChange}
                  inline
               />
            )}
         </Box>
      </Stack>
   );
}
