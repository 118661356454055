import Box from "@mui/material/Box";

export default function VerticalLabel({ children }) {
   return (
      <Box
         sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translateY(-50%) translateX(-50%) rotate(-90deg)",
            fontWeight: "bold",
            textTransform: "uppercase",
            width: 65,
            textAlign: "center",
            fontSize: 7,
         }}
      >
         {children}
      </Box>
   );
}
