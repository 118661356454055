import React, { useState, Children, isValidElement } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import EditIcon from "images/icon-edit.svg";

export default function BoxWrapper({
   children,
   childComponent,
   title,
   titleInset = false,
   padding = 4,
   buttons,
   edit,
   height,
   transparent,
}) {
   const theme = useTheme();
   const [editMode, setEditMode] = useState(false);

   const themeStyle = () => {
      if (transparent) {
         return "Transparent";
      } else {
         return "Parent";
      }
   };

   const toggleEditMode = () => {
      setEditMode(!editMode);
   };

   return (
      <>
         {title && !titleInset && (
            <Typography variant='h1' component='div'>
               {title}
            </Typography>
         )}

         {themeStyle && (
            <Box
               sx={{
                  ...theme["boxStyle" + themeStyle()],
                  px: padding,
                  maxHeight: height ? height : "auto",
                  overflow: height ? "scroll" : "visible",
               }}
            >
               {edit && (
                  <Button
                     sx={{ ...theme.editSectionButton }}
                     variant='editicon'
                     size='large'
                     onClick={toggleEditMode}
                  >
                     <img src={EditIcon} />
                  </Button>
               )}

               <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='flex-end'
                  spacing={4}
                  sx={{ width: "100%", px: padding === 0 ? 3 : 0 }}
               >
                  {title && titleInset && (
                     <Typography
                        variant='h2'
                        component='div'
                        sx={{
                           mb: 2,
                           whiteSpace: "nowrap",
                        }}
                     >
                        {title}
                     </Typography>
                  )}
                  <Box sx={{ width: "100%", textAlign: "right" }}>
                     {buttons && <>{buttons}</>}
                  </Box>
               </Stack>

               {childComponent && (
                  <>
                     {Children.map(childComponent, (child, index) => {
                        if (isValidElement(child)) {
                           return React.cloneElement(
                              child,
                              {
                                 editMode: editMode,
                              },
                              null
                           );
                        }
                     })}
                  </>
               )}

               {!childComponent && <>{children}</>}
            </Box>
         )}
      </>
   );
}
