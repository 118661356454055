import React, { useEffect } from "react";
import dateFormat from "dateformat";

//HOOKS
import useGlobal from "hooks/useGlobal";

// MUI
import { Box, Table, TableBody, TableCell, TableRow } from "@mui/material";

import { useTheme } from "@mui/material/styles";

export default function Schedules({ projectId, pk }) {
   const theme = useTheme();
   const model = "schedules";

   const [
      schedule,
      all,
      { getAll, get, updateModelData, add, remove },
      lastAdded,
      lastUpdated,
   ] = useGlobal();

   useEffect(() => {
      if (pk) {
         getAll(model, pk);
      }
   }, [pk, lastAdded, lastUpdated]);

   return (
      <Table layout='childTable'>
         <TableBody>
            {all.schedules &&
               all.schedules.map((row, i) => (
                  <TableRow key={"schedule-row" + i}>
                     <TableCell sx={{ width: 200 }}>
                        {dateFormat(
                           new Date("2022-03-01T" + row.time),
                           "shortTime"
                        )}
                     </TableCell>
                     <TableCell>
                        <b>{row.title}</b>

                        <Box sx={{ color: theme.palette.text.secondary }}>
                           {row.description}
                        </Box>
                     </TableCell>
                  </TableRow>
               ))}
         </TableBody>
      </Table>
   );
}
