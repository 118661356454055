import React, { useEffect } from "react";
import dateFormat from "dateformat";

import _ from "lodash";

//HOOKS
import useGlobal from "hooks/useGlobal";
import RoleAutoComplete from "components/project/RoleAutoComplete";

//COMPONENTS
import BoxWrapper from "components/wrappers/BoxWrapper";

// MUI
import {
   Button,
   ButtonGroup,
   Box,
   Table,
   TableBody,
   TableCell,
   TableHead,
   TableRow,
   Checkbox,
   Typography,
   Stack,
   IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { Delete } from "@mui/icons-material";

export default function Staff({ projectId, roleType, refresh }) {
   const theme = useTheme();

   const model = "projectroles";

   const [
      projectrole,
      all,
      { getAll, get, updateModelData, add, remove, clearParent, find },
   ] = useGlobal();

   useEffect(() => {
      if (projectId) {
         getAll(model, projectId);
      }
      getAll("roles");
   }, []);

   const saveChange = (data) => {
      _.each(data, function (val, key) {
         if (val === "true") {
            data[key] = Boolean(val);
         }
      });

      updateModelData(data).then((response) => getAll(model, projectId));
   };

   const filterRoles = (roleType) => {
      const parsed = _.filter(all.projectroles, function (row) {
         if (row.role && row.role.type) {
            return row.role.type === roleType;
         } else {
            return "0";
         }
         return row.role.type === roleType;
      });

      if (parsed.length > 0) {
         return parsed;
      } else {
         return [];
      }
   };

   const allowedRoles = (roleType) => {
      const parsed = _.filter(all.roles, function (row) {
         if (row.type && roleType) {
            return row.type === roleType;
         }
      });

      if (parsed.length > 0) {
         return parsed;
      } else {
         return [];
      }
   };

   return (
      <Box sx={{ mt: 4 }}>
         <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            spacing={1}
            sx={{
               mt: 6,
               mb: 4,
            }}
         >
            <Typography
               variant='h1'
               component='div'
               sx={{
                  m: 0,
                  width: "100%",
                  whiteSpace: "nowrap",
               }}
            >
               Staff
            </Typography>
         </Stack>

         <BoxWrapper
            title={roleType}
            titleInset
            padding={0}
            buttons={
               <Button
                  variant='contained'
                  color='success'
                  onClick={() => add(model, projectId).then(() => refresh())}
               >
                  Add Role
               </Button>
            }
         >
            {filterRoles(roleType).length > 0 && (
               <Table layout='childTable'>
                  <TableHead>
                     <TableRow>
                        <TableCell sx={{ width: "25%" }}></TableCell>
                        <TableCell sx={{ width: "25%" }}></TableCell>
                        <TableCell sx={{ width: "10%" }}>CONTACTED</TableCell>
                        <TableCell sx={{ width: "40%", textAlign: "center" }}>
                           CONFIRMATION
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {roleType &&
                        all.roles &&
                        filterRoles(roleType).map((projectrole, i) => (
                           <>
                              {projectrole && (
                                 <TableRow
                                    key={"projectroles" + projectrole.id}
                                    className='listTableRow'
                                 >
                                    <TableCell>
                                       <RoleAutoComplete
                                          selectedValue={
                                             projectrole.role
                                                ? projectrole.role.id
                                                : null
                                          }
                                          selectedName={
                                             projectrole.role
                                                ? projectrole.role.title
                                                : ""
                                          }
                                          options={allowedRoles(roleType)}
                                          label='Role'
                                          updateData={(value) =>
                                             saveChange({
                                                pk: projectrole.id,
                                                name: "roleId",
                                                model: model,
                                                value: value,
                                                query: JSON.stringify({
                                                   model: "projectrolepeople",
                                                   pk:
                                                      projectrole.projectroleperson &&
                                                      projectrole
                                                         .projectroleperson.id,
                                                   updates: {
                                                      personId: null,
                                                      status: null,
                                                   },
                                                }),
                                             })
                                          }
                                       />

                                       {/* <Select
                              fullWidth
                              defaultValue={
                                 projectrole.role && projectrole.role.id
                              }
                              onChange={(e) =>
                                 saveChange(e, {
                                    pk: projectrole.id,
                                    name: "roleId",
                                    model: model,
                                    query: JSON.stringify({
                                       model: "projectrolepeople",
                                       pk:
                                          projectrole.projectroleperson &&
                                          projectrole.projectroleperson.id,
                                       updates: {
                                          personId: null,
                                          status: null,
                                       },
                                    }),
                                 })
                              }
                              className={!projectrole.role && "highlight"}
                           >
                              {projectrole && !projectrole.role && (
                                 <MenuItem key={"roleoptionNull"} value=''>
                                    Select Role
                                 </MenuItem>
                              )}

                              {props.allroles.map((row) => (
                                 <MenuItem
                                    key={"roleoption" + row.id}
                                    value={row.id}
                                 >
                                    {row.title}
                                 </MenuItem>
                              ))}
                           </Select> */}
                                    </TableCell>

                                    <TableCell>
                                       <RoleAutoComplete
                                          selectedValue={
                                             projectrole.projectroleperson
                                                ? projectrole.projectroleperson
                                                     .personId
                                                : null
                                          }
                                          selectedName={
                                             projectrole.projectroleperson
                                                .person
                                                ? projectrole.projectroleperson
                                                     .person.first +
                                                  " " +
                                                  projectrole.projectroleperson
                                                     .person.last
                                                : ""
                                          }
                                          options={
                                             projectrole.role &&
                                             projectrole.role.peopleroles &&
                                             projectrole.role.peopleroles
                                                ? projectrole.role.peopleroles.map(
                                                     (role) => ({
                                                        id: role.peopleId,
                                                        title: role.person
                                                           ? role.person.first +
                                                             " " +
                                                             role.person.last
                                                           : "Unknown Person (No Name)",
                                                     })
                                                  )
                                                : []
                                          }
                                          label='Person'
                                          updateData={(value) =>
                                             saveChange({
                                                pk: projectrole.projectroleperson
                                                   ? projectrole
                                                        .projectroleperson.id
                                                   : "",
                                                name: "personId",
                                                value: value,
                                                model: "projectrolepeople",
                                             })
                                          }
                                          className={
                                             projectrole.projectroleperson &&
                                             !projectrole.projectroleperson
                                                .person &&
                                             "highlight"
                                          }
                                       />
                                    </TableCell>

                                    <TableCell align='center'>
                                       {projectrole.projectroleperson && (
                                          <>
                                             <Checkbox
                                                type='checkbox'
                                                checked={
                                                   !!parseInt(
                                                      projectrole
                                                         .projectroleperson
                                                         .status
                                                   )
                                                }
                                                onClick={(e) =>
                                                   saveChange({
                                                      model: "projectrolepeople",
                                                      pk: projectrole
                                                         .projectroleperson.id,
                                                      name: "status",
                                                      value: +e.target.checked,
                                                   })
                                                }
                                             />
                                          </>
                                       )}
                                    </TableCell>
                                    <TableCell>
                                       {projectrole.projectroleperson &&
                                          projectrole.projectroleperson
                                             .projectpeopledates.length > 0 &&
                                          projectrole.projectroleperson.projectpeopledates.map(
                                             (projectpersondate, o) => (
                                                <Table
                                                   fullWidth
                                                   size='small'
                                                   key={"roles-" + i + "-" + o}
                                                >
                                                   <TableRow>
                                                      <TableCell
                                                         sx={{
                                                            width: "50%",
                                                            border:
                                                               o === 0
                                                                  ? "none !important"
                                                                  : "inherit",
                                                         }}
                                                      >
                                                         {projectpersondate
                                                            .projectdate.date &&
                                                            dateFormat(
                                                               projectpersondate
                                                                  .projectdate
                                                                  .date +
                                                                  "T00:00:00.000",
                                                               "mmm d"
                                                            )}
                                                      </TableCell>
                                                      <TableCell
                                                         sx={{
                                                            width: "50%",
                                                            border:
                                                               o === 0
                                                                  ? "none !important"
                                                                  : "inherit",
                                                         }}
                                                      >
                                                         <ButtonGroup className='buttonGroup'>
                                                            <Button
                                                               sx={{
                                                                  color: "#fff",
                                                               }}
                                                               variant={
                                                                  projectpersondate.confirmed ===
                                                                  0
                                                                     ? "contained"
                                                                     : "outlined"
                                                               }
                                                               color={
                                                                  projectpersondate.confirmed ===
                                                                  0
                                                                     ? "error"
                                                                     : "primary"
                                                               }
                                                               onClick={() =>
                                                                  saveChange({
                                                                     model: "projectpeopledates",
                                                                     pk: projectpersondate.id,
                                                                     name: "confirmed",
                                                                     value: 0,
                                                                  })
                                                               }
                                                            >
                                                               DECLINED
                                                            </Button>
                                                            <Button
                                                               sx={{
                                                                  color: "#fff",
                                                               }}
                                                               variant={
                                                                  projectpersondate.confirmed ===
                                                                  1
                                                                     ? "contained"
                                                                     : "outlined"
                                                               }
                                                               color={
                                                                  projectpersondate.confirmed ===
                                                                  1
                                                                     ? "success"
                                                                     : "primary"
                                                               }
                                                               onClick={(
                                                                  value
                                                               ) =>
                                                                  saveChange({
                                                                     model: "projectpeopledates",
                                                                     pk: projectpersondate.id,
                                                                     name: "confirmed",
                                                                     value: 1,
                                                                  })
                                                               }
                                                            >
                                                               ACCEPTED
                                                            </Button>
                                                         </ButtonGroup>
                                                      </TableCell>
                                                   </TableRow>
                                                </Table>
                                             )
                                          )}
                                    </TableCell>

                                    <TableCell>
                                       <IconButton
                                          aria-label='delete'
                                          onClick={() =>
                                             remove(
                                                model,
                                                projectrole.id,
                                                projectId
                                             )
                                          }
                                       >
                                          <Delete />
                                       </IconButton>
                                    </TableCell>
                                 </TableRow>
                              )}
                           </>
                        ))}
                  </TableBody>
               </Table>
            )}
         </BoxWrapper>
      </Box>
   );
}
