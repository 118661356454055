import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import axios from "axios";

// AUTH
import AuthService from "services/auth.service";
import authHeader from "services/auth-header";
import Login from "components/auth/Login";
import Register from "components/auth/Register";
import Profile from "components/auth/Profile";
import Home from "components/containers/Home";
import User from "components/containers/User";
import Moderator from "components/containers/Moderator";
import Admin from "components/containers/Admin";

// COMPONENTS
import Debug from "components/widgets/Debug";
import People from "pages/People";
import Person from "pages/Person";
import Roles from "pages/Roles";
import Projects from "pages/Projects";
import Project from "pages/Project";

import ResponsiveAppBar from "components/ResponsiveAppBar";

// MUI
import { Grid } from "@mui/material";

import "./index.css";

const App = () => {
   let navigate = useNavigate();

   const [showModerator, setShowModerator] = useState(false);
   const [showAdmin, setShowAdmin] = useState(false);
   const [currentUser, setCurrentUser] = useState(undefined);

   const logOut = () => {
      AuthService.logout();
   };

   useEffect(() => {
      const user = AuthService.getCurrentUser();

      axios
         .get("/api/auth/verifytoken", { headers: authHeader() })
         .then((response) => {
            if (response.data === "verified") {
               if (JSON.parse(localStorage.getItem("user"))) {
                  setCurrentUser(user);
                  setShowModerator(user.roles.includes("ROLE_MODERATOR"));
                  setShowAdmin(user.roles.includes("ROLE_ADMIN"));
               } else {
                  setCurrentUser(undefined);
               }
            }
         })
         .catch(function (error) {
            if (error.response.status === 401) {
               logOut();
               navigate("/", { replace: true });
            }
         });
   }, []);

   return (
      <>
         <Grid container>
            <Grid item xs={10}>
               <Routes>
                  <Route
                     element={
                        <ResponsiveAppBar user={currentUser} logOut={logOut} />
                     }
                  >
                     {showModerator && (
                        <>
                           <Route path='/mod' element={<Moderator />} />
                           <Route path='/profile' element={<Profile />} />

                           <Route path='/people' element={<People />} />
                           <Route
                              path='/people/:personId'
                              element={<Person />}
                           />
                           <Route path='/roles' element={<Roles />} />
                           <Route path='/projects' element={<Projects />} />
                           <Route
                              path='/project/:projectId'
                              element={<Project />}
                           />
                        </>
                     )}

                     {showAdmin && (
                        <>
                           <Route path='/admin' element={<Admin />} />
                           <Route path='/profile' element={<Profile />} />

                           <Route path='/people' element={<People />} />
                           <Route
                              path='/people/:personId'
                              element={<Person />}
                           />
                           <Route path='/roles' element={<Roles />} />
                           <Route path='/projects' element={<Projects />} />
                           <Route
                              path='/project/:projectId'
                              element={<Project />}
                           />
                        </>
                     )}

                     {currentUser && (
                        <>
                           <Route path='/user' element={<User />} />
                           <Route path='/profile' element={<Profile />} />

                           <Route path='/people' element={<People />} />
                           <Route
                              path='/people/:personId'
                              element={<Person />}
                           />
                           <Route path='/roles' element={<Roles />} />
                           <Route path='/projects' element={<Projects />} />
                           <Route
                              path='/project/:projectId'
                              element={<Project />}
                           />
                        </>
                     )}

                     {!currentUser && !showAdmin && !showModerator && (
                        <>
                           <Route path='/register' element={<Register />} />
                           <Route path='/' element={<Login />} />
                        </>
                     )}
                     <Route path='/login' element={<Login />} />
                     <Route path='/' element={<Home />} />
                  </Route>
               </Routes>
            </Grid>
            <Grid item xs={2} sx={{ position: "relative" }}>
               <Debug />
            </Grid>
         </Grid>
      </>
   );
};
export default App;
