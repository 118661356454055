import React, { useState, useEffect, useContext } from "react";

import { SocketContext } from "context/socket";

// MUI
import { Box } from "@mui/material";

import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";

function Debug() {
   const socket = useContext(SocketContext);
   const [debug, setDebug] = useState([]);

   useEffect(() => {
      socket.on("log", (payload) => {
         setTimeout(function () {
            setDebug((prevState) => {
               let date = JSON.stringify(new Date().toString());
               return [
                  { date: date, payload: payload },
                  ...prevState.slice(0, 20),
               ];
            });
         }, 500);
      });
   }, [socket]);

   return (
      <Box
         sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            right: 0,
            top: 0,
            opacity: 0.8,
            zIndex: 1000,
         }}
         className='debug'
      >
         <h3>DEBUG</h3>
         {debug.map((row, i) => (
            <span key={"debug" + i}>
               <b style={{ fontSize: ".8em" }}>{row.date}: </b>
               <JSONPretty id='json-pretty' data={row.payload}></JSONPretty>
            </span>
         ))}
      </Box>
   );
}

export default Debug;
