import React, { useEffect } from "react";

//HOOKS
import useGlobal from "hooks/useGlobal";

// MUI
import {
   Table,
   TableBody,
   TableCell,
   TableRow,
   Select,
   MenuItem,
   IconButton,
   Typography,
   Stack,
} from "@mui/material";

import { AddCircle, Delete } from "@mui/icons-material";

export default function EditPersonRoles(props) {
   const model = "peopleroles";
   const [row, all, { getAll, get, updateModelData, add, remove }] =
      useGlobal();

   useEffect(() => {
      getAll("roles");
   }, []);

   useEffect(() => {
      if (props.personId) {
         getAll(model, props.personId);
      }
   }, [props.personId, props.refresh]);

   const saveChange = (event, custom) => {
      let data = {
         ...custom,
         model: model,
         value: event.target.value,
      };

      updateModelData(data);
   };

   return (
      <>
         <Stack direction='row' alignItems='center' sx={{ mb: 2 }}>
            <Typography variant='h2' component='div' sx={{ m: 0 }}>
               ROLES
            </Typography>
            <IconButton
               aria-label='add'
               onClick={() => add(model, props.personId)}
            >
               <AddCircle />
            </IconButton>
         </Stack>
         <Table size='small' layout='masterTable'>
            <TableBody>
               {all.peopleroles &&
                  all.peopleroles.map((personrole, i) => (
                     <TableRow key={"personrolesrow" + personrole.id}>
                        <TableCell width='80%'>
                           <Select
                              onChange={(e) =>
                                 saveChange(e, {
                                    pk: personrole.id,
                                    name: "roleId",
                                 })
                              }
                              defaultValue={personrole.roleId}
                              fullWidth
                           >
                              {all.roles &&
                                 all.roles.map((row) => (
                                    <MenuItem
                                       key={"personrole" + row.id}
                                       value={row.id}
                                    >
                                       {row.title}
                                    </MenuItem>
                                 ))}
                           </Select>
                        </TableCell>
                        <TableCell>
                           <IconButton
                              aria-label='delete'
                              color='error'
                              onClick={() =>
                                 remove(model, personrole.id, props.personId)
                              }
                           >
                              <Delete />
                           </IconButton>
                        </TableCell>
                     </TableRow>
                  ))}
            </TableBody>
         </Table>
      </>
   );
}
