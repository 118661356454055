import React, { useState, useRef, useEffect } from "react";
import API from "handlers/API";

import _ from "lodash";

export default function Invoices(props) {
   const [schedule, setSchedule] = useState([]);

   useEffect(() => {
      API.get("/people/schedule/" + props.id).then((res) => {
         setSchedule(res.data);
      });
   }, []);

   return (
      <div className='modalBlock'>
         <h3>Invoices</h3>
      </div>
   );
}
