import axios from "axios";
import authHeader from "services/auth-header";

export default new (class API {
   get(url) {
      return axios.get(url, { headers: authHeader() });
   }

   post(url, data) {
      return axios.post(url, data, { headers: authHeader() });
   }

   download(url) {
      return axios.get(url, {
         responseType: "arraybuffer",
         headers: authHeader(),
      });
   }
})();
