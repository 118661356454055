import React, { useEffect } from "react";

import FadeIn from "components/wrappers/FadeIn";

// BLOCKS
import Company from "components/project/Company";
import Venue from "components/project/Venue";

//HOOKS
import useGlobal from "hooks/useGlobal";

// MUI
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import { useTheme } from "@mui/material/styles";

export default function OverviewVenue({ project, editMode, refresh }) {
   const theme = useTheme();

   const [
      programs,
      all,
      { getAll, get, updateModelData, add, remove, clearParent, find },
   ] = useGlobal();

   useEffect(() => {
      getAll("venues");
   }, []);

   const handleKeyPress = (e) => {
      if (e.key === "Enter") {
         e.target.blur();
      }
   };

   return (
      <>
         {editMode && (
            <FadeIn>
               <Box sx={{ pt: 6, px: 3, mb: 4 }}>
                  <FormControl sx={{ width: "100%" }}>
                     <InputLabel id='venueLabel'>Select Venue</InputLabel>
                     <Select
                        labelId='venueLabel'
                        id='venueInput'
                        label='Select Venue'
                        variant='outlined'
                        name='venueId'
                        defaultValue={project.venueId}
                        onChange={(e) =>
                           updateModelData({
                              model: "projects",
                              pk: project.id,
                              name: "venueId",
                              value: e.target.value,
                           }).then((response) => refresh())
                        }
                        fullWidth
                     >
                        <MenuItem value={null}>--</MenuItem>
                        {all.venues &&
                           all.venues.map((row, i) => (
                              <MenuItem
                                 selected={project.venueId === row.id ? 1 : 0}
                                 key={"venue" + row.id}
                                 value={row.id}
                              >
                                 {row.venue}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               </Box>

               {project.venueId && <Venue pk={project.venueId} />}
               {project.companyId && <Company pk={project.companyId} />}
            </FadeIn>
         )}

         {!editMode && (
            <FadeIn>
               {project.venueId && <Venue pk={project.venueId} />}
               {project.companyId && <Company pk={project.companyId} />}
            </FadeIn>
         )}
      </>
   );
}
