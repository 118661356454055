import React, {
   useCallback,
   useEffect,
} from "react";
import { useNavigate } from "react-router-dom";

import dateFormat from "dateformat";

import PageWrapper from "components/wrappers/PageWrapper";
import VerticalLabel from "components/VerticalLabel";
import ChipWrapper from "components/wrappers/ChipWrapper";

//HOOKS
import useGlobal from "hooks/useGlobal";

// MUI
import {
   Box,
   Button,
   Typography,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

function Projects() {
   const navigate = useNavigate();
   const theme = useTheme();

   const model = "projects";
   const [
      project,
      all,
      { getAll, get, updateModelData, add, remove },
      lastAdded,
   ] = useGlobal();

   useEffect(() => {
      {
         /* getAll(model); */
      }
   }, []);

   useEffect(() => {
      getAll(model);
   }, [lastAdded]);

   const closeProject = useCallback(() => {
      getAll(model);
   }, []);

   return (
      <PageWrapper>
         <Box sx={{ my: 6, width: "100%" }}>
            <Typography variant='h2' component='h2'>
               Projects
               <Button
                  variant='contained'
                  color='success'
                  style={{ float: "right" }}
                  onClick={() => add(model)}
               >
                  ADD PROJECT
               </Button>
            </Typography>

            <TableContainer sx={{ mt: 4 }}>
               <Table layout='masterTable'>
                  <TableHead>
                     <TableRow>
                        <TableCell
                           sx={{
                              width: 10,
                              p: 0,
                           }}
                        ></TableCell>
                        <TableCell
                           sm='2'
                           sx={{
                              pl: 4,
                           }}
                        >
                           Project Name
                        </TableCell>
                        <TableCell sm='2'>Program</TableCell>
                        <TableCell sm='1'>Client</TableCell>
                        <TableCell sm='2'>Dates</TableCell>
                        <TableCell sm='2'>Location</TableCell>
                        <TableCell sm='1'>Links</TableCell>
                        <TableCell sm='1'>Status</TableCell>
                        <TableCell sm='1'></TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {all.projects &&
                        all.projects.map((row, i) => (
                           <TableRow key={"project" + row.id}>
                              <TableCell
                                 sx={{
                                    width: 30,
                                    p: 0,
                                    overflow: "hidden",
                                    backgroundColor:
                                       theme.palette[
                                          row.type
                                             .toLowerCase()
                                             .replace(/\s/g, "")
                                       ].main + " !important",
                                    position: "relative",
                                 }}
                              >
                                 <VerticalLabel>{row.type}</VerticalLabel>
                              </TableCell>
                              <TableCell
                                 sm='2'
                                 sx={{
                                    pl: 4,
                                    borderTop: "1px solid #414755",
                                 }}
                              >
                                 {row.project && <b>{row.project}</b>}
                                 {!row.project && (
                                    <b style={{ color: "#f00" }}>NEW PROJECT</b>
                                 )}
                              </TableCell>
                              <TableCell
                                 sm='2'
                                 sx={{
                                    borderTop: "1px solid #414755",
                                 }}
                              >
                                 {row.program && row.program.program}
                              </TableCell>
                              <TableCell
                                 sm='1'
                                 sx={{
                                    borderTop: "1px solid #414755",
                                 }}
                              >
                                 {row.company && row.company.company}
                              </TableCell>
                              <TableCell
                                 sm='2'
                                 sx={{
                                    borderTop: "1px solid #414755",
                                 }}
                              >
                                 {row.projectdates &&
                                    row.projectdates.length > 0 &&
                                    row.projectdates[0].date && (
                                       <>
                                          {dateFormat(
                                             row.projectdates[0].date +
                                                "T00:00:00.000",
                                             "m/dd"
                                          )}
                                          -
                                          {dateFormat(
                                             Date.parse(
                                                row.projectdates[
                                                   row.projectdates.length - 1
                                                ].date + "T00:00:00.000"
                                             ),
                                             "m/dd"
                                          )}
                                       </>
                                    )}
                              </TableCell>
                              <TableCell
                                 sm='2'
                                 sx={{
                                    borderTop: "1px solid #414755",
                                 }}
                              >
                                 {row.venue && row.venue.venue}
                              </TableCell>
                              <TableCell
                                 sm='1'
                                 className='links'
                                 sx={{
                                    borderTop: "1px solid #414755",
                                 }}
                              >
                                 {row.roslink && (
                                    <Button
                                       href={row.roslink}
                                       variant='highlight'
                                       target='_blank'
                                    >
                                       ROS
                                    </Button>
                                 )}
                                 {row.vodlink && (
                                    <Button
                                       href={row.vodlink}
                                       variant='highlight'
                                       target='_blank'
                                    >
                                       VOD
                                    </Button>
                                 )}
                                 {row.sizzlelink && (
                                    <Button
                                       href={row.sizzlelink}
                                       variant='highlight'
                                       target='_blank'
                                    >
                                       SIZZLE
                                    </Button>
                                 )}
                                 {row.scriptlink && (
                                    <Button
                                       href={row.scriptlink}
                                       variant='highlight'
                                       target='_blank'
                                    >
                                       SCRIPT
                                    </Button>
                                 )}
                              </TableCell>
                              <TableCell
                                 sm='1'
                                 sx={{
                                    borderTop: "1px solid #414755",
                                 }}
                              >
                                 {row.status && (
                                    <ChipWrapper
                                       label={row.status}
                                       size='small'
                                    />
                                 )}
                              </TableCell>
                              <TableCell
                                 sm='1'
                                 align='right'
                                 sx={{
                                    borderTop: "1px solid #414755",
                                 }}
                              >
                                 <Button
                                    variant='contained'
                                    color='secondary'
                                    onClick={() => {
                                       navigate("/project/" + row.id);
                                    }}
                                 >
                                    VIEW
                                 </Button>
                              </TableCell>
                           </TableRow>
                        ))}
                  </TableBody>
               </Table>
            </TableContainer>
         </Box>
      </PageWrapper>
   );
}

export default Projects;
