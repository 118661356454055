import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

// COMPONENTS
import ScheduleTabs from "components/schedule/ScheduleTabs";
import StaffTabs from "components/staff/StaffTabs";
import BoxWrapper from "components/wrappers/BoxWrapper";

import OverviewProject from "components/project/OverviewProject";
import OverviewInformation from "components/project/OverviewInformation";
import OverviewLinks from "components/project/OverviewLinks";
import OverviewVenue from "components/project/OverviewVenue";
import OverviewLogo from "components/project/OverviewLogo";

//HOOKS
import useGlobal from "hooks/useGlobal";
import API from "handlers/API";

// MUI
import { Button, Box, Typography, Grid } from "@mui/material";

import { useTheme } from "@mui/material/styles";

// CUSTOM ICONS
import EditIcon from "images/icon-edit.svg";

function TabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role='tabpanel'
         hidden={value !== index}
         id={`schedule-tabpanel-${index}`}
         aria-labelledby={`schedule-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box sx={{ p: 2 }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
};

function a11yProps(index) {
   return {
      id: `schedule-tab-${index}`,
      "aria-controls": `schedule-tabpanel-${index}`,
   };
}

export default function Overview({ projectId }) {
   const theme = useTheme();
   const model = "projects";

   const [
      project,
      all,
      { getAll, get, updateModelData, add, remove, clearParent, find },
   ] = useGlobal();

   const [tab, setTab] = useState(0);
   const handleTabChange = (event, newValue) => {
      setTab(newValue);
   };

   useEffect(() => {
      if (projectId) {
         get(model, projectId);
      }
   }, []);

   const refresh = () => {
      get(model, projectId);
   };

   return (
      <>
         <Typography variant='h1' component='div'>
            Project Overview
            <Button
               variant='contained'
               color='secondary'
               style={{ float: "right" }}
               onClick={() =>
                  API.download("/api/generate/pdf/" + projectId).then(function (
                     response
                  ) {
                     var headers = response.headers;
                     var blob = new Blob([response.data], {
                        type: headers["content-type"],
                     });
                     var link = document.createElement("a");
                     link.href = window.URL.createObjectURL(blob);
                     link.download =
                        headers["content-disposition"].split("=")[1];
                     link.click();
                  })
               }
            >
               Download PDF
            </Button>
         </Typography>

         <Grid container spacing={3}>
            <Grid item xs={9}>
               <BoxWrapper
                  edit
                  childComponent={
                     <OverviewProject project={project} refresh={refresh} />
                  }
               />

               <BoxWrapper
                  edit
                  childComponent={
                     <OverviewInformation project={project} refresh={refresh} />
                  }
               />

               <BoxWrapper
                  title='Daily Rundown'
                  titleInset
                  padding={0}
                  childComponent={
                     <ScheduleTabs projectId={projectId} parent='overview' />
                  }
               />

               <BoxWrapper
                  title='People'
                  titleInset
                  padding={0}
                  childComponent={
                     <StaffTabs projectId={projectId} parent='overview' />
                  }
               />
            </Grid>
            <Grid item xs={3}>
               <BoxWrapper
                  title='Links'
                  titleInset
                  edit
                  childComponent={
                     <OverviewLinks project={project} refresh={refresh} />
                  }
               />
               <BoxWrapper
                  padding={0}
                  edit
                  childComponent={
                     <OverviewVenue project={project} refresh={refresh} />
                  }
               />
               <BoxWrapper
                  childComponent={
                     <OverviewLogo project={project} refresh={refresh} />
                  }
               />
            </Grid>
         </Grid>
      </>
   );
}
