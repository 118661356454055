import React, { useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// MUI
import { Box, Button } from "@mui/material";

export default function AddTime({ id, value, callback }) {
   const [time, setTime] = useState(
      value ? new Date("2022-03-01T" + value) : ""
   );
   const [isOpen, setIsOpen] = useState(false);

   const handleChange = (e) => {
      setIsOpen(!isOpen);
      setTime(e);
      callback(id, e);
   };
   const handleClick = (e) => {
      e.preventDefault();
      setIsOpen(!isOpen);
   };
   return (
      <>
         <Button variant='inputField' onClick={handleClick} fullWidth>
            {value}
         </Button>
         {isOpen && (
            <>
               <Box
                  sx={{
                     position: "absolute",
                     left: "50%",
                     transform: "translateX(-50%)",
                     zIndex: 1000,
                  }}
               >
                  <DatePicker
                     selected={time}
                     onChange={handleChange}
                     showTimeSelect
                     showTimeSelectOnly
                     timeIntervals={15}
                     timeCaption='Time'
                     dateFormat='hh:mm aa'
                     className='timeInput'
                     inline
                  />
               </Box>
               <Box
                  sx={{
                     position: "fixed",
                     top: 0,
                     left: 0,
                     width: "100%",
                     height: "100%",
                     zIndex: 900,
                  }}
                  onClick={handleClick}
               />
            </>
         )}
      </>
   );
}
