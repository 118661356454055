import React, { useState, useEffect } from "react";
import { useNavigate, Link, Outlet } from "react-router-dom";

import PageWrapper from "components/wrappers/PageWrapper";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

import logoAtomic from "images/logo-appbar.svg";
import titleResources from "images/title-resources.svg";

// AUTH
import AuthService from "services/auth.service";

const pages = [
   { url: "/", title: "Home" },
   { url: "/people", title: "People", authReq: true },
   { url: "/projects", title: "Projects", authReq: true },
   { url: "/roles", title: "Roles", authReq: true },
];

const ResponsiveAppBar = (props) => {
   const navigate = useNavigate();

   const [anchorElNav, setAnchorElNav] = useState();
   const [anchorElUser, setAnchorElUser] = useState();

   const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElNav(event.currentTarget);
   };
   const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
   };

   const handleCloseNavMenu = () => {
      setAnchorElNav(null);
   };

   const handleCloseUserMenu = () => {
      setAnchorElUser(null);
   };

   // AUTH
   const [currentUser, setCurrentUser] = useState(undefined);

   useEffect(() => {
      setCurrentUser(props.user);
   }, [props.user]);

   const logOut = () => {
      AuthService.logout();
      setCurrentUser(undefined);
   };

   return (
      <>
         <Toolbar
            variant='headerBar'
            disableGutters
            sx={{ backgroundColor: "#141B2B", pr: 4 }}
         >
            <img src={logoAtomic} height='100%' />

            <Box sx={{ mr: 5, pt: 0.2 }}>
               <img src={titleResources} />
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
               {pages.map((page) => (
                  <>
                     {!page.authReq && (
                        <Button
                           key={page.title}
                           onClick={() => {
                              navigate(page.url);
                              handleCloseNavMenu();
                           }}
                           sx={{
                              color: "#B3B6BB",
                              display: "block",
                              mr: 2,
                           }}
                        >
                           {page.title}
                        </Button>
                     )}

                     {page.authReq && currentUser && (
                        <Button
                           key={page.title}
                           onClick={() => {
                              navigate(page.url);
                              handleCloseNavMenu();
                           }}
                           sx={{
                              color: "#B3B6BB",
                              display: "block",
                              mr: 2,
                           }}
                        >
                           {page.title}
                        </Button>
                     )}
                  </>
               ))}
            </Box>

            {currentUser ? (
               <Box sx={{ flexGrow: 0 }}>
                  <IconButton onClick={handleOpenUserMenu}>
                     <Avatar
                        sx={{
                           bgcolor: "#414755",
                           border: "1px solid #797D8A",
                           color: "#EC6508",
                        }}
                     >
                        A
                     </Avatar>
                  </IconButton>
                  <Menu
                     sx={{ mt: "38px", ml: "-7px" }}
                     id='menu-appbar'
                     variant='headerBar'
                     anchorEl={anchorElUser}
                     anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                     }}
                     keepMounted
                     transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                     }}
                     open={Boolean(anchorElUser)}
                     onClose={handleCloseUserMenu}
                  >
                     <MenuItem
                        key={"profile"}
                        onClick={handleCloseUserMenu}
                        component={Link}
                        to={"/profile"}
                     >
                        <Typography textAlign='center'>Profile</Typography>
                     </MenuItem>
                     <MenuItem
                        key={"logout"}
                        onClick={logOut}
                        component={Link}
                        to={"/login"}
                     >
                        <Typography textAlign='center'>Logout</Typography>
                     </MenuItem>
                  </Menu>
               </Box>
            ) : (
               <Box sx={{ flexGrow: 0 }}>
                  <Tooltip title='Open settings'>
                     <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar
                           alt='Avatar'
                           src='/static/images/avatar/2.jpg'
                        />
                     </IconButton>
                  </Tooltip>
                  <Menu
                     sx={{ mt: "45px" }}
                     id='menu-appbar'
                     anchorEl={anchorElUser}
                     anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                     }}
                     keepMounted
                     transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                     }}
                     open={Boolean(anchorElUser)}
                     onClose={handleCloseUserMenu}
                  >
                     <MenuItem
                        key={"login"}
                        onClick={handleCloseUserMenu}
                        component={Link}
                        to={"/login"}
                     >
                        <Typography textAlign='center'>Login</Typography>
                     </MenuItem>
                     <MenuItem
                        key={"register"}
                        onClick={handleCloseUserMenu}
                        component={Link}
                        to={"/register"}
                     >
                        <Typography textAlign='center'>Register</Typography>
                     </MenuItem>
                  </Menu>
               </Box>
            )}
         </Toolbar>

         <PageWrapper width='80vw'>
            <Outlet />
         </PageWrapper>
      </>
   );
};
export default ResponsiveAppBar;
