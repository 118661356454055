import React, { useState, useEffect } from "react";

//HOOKS
import useGlobal from "hooks/useGlobal";

// MUI
import { Autocomplete, TextField } from "@mui/material";

export default function RoleAutoComplete(props) {
   const [value, setValue] = useState(null);
   const [inputValue, setInputValue] = useState("");

   useEffect(() => {
      setValue(props.selectedValue);
      setInputValue(props.selectedName);
   }, [props]);

   const handleBlur = (e) => {
      if (!e.target.value) {
         setValue(props.selectedValue);
         setInputValue(props.selectedName);
      }
   };

   return (
      <>
         <Autocomplete
            fullWidth
            value={value}
            onChange={(event, newValue) => {
               if (newValue) {
                  setValue(newValue.id);
                  setInputValue(newValue.title);

                  props.updateData(newValue.id);
               } else {
                  setValue(null);
               }
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
               if (newInputValue !== "undefined") {
                  setInputValue(newInputValue);
               }
            }}
            onBlur={handleBlur}
            options={props.options}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
               <TextField
                  {...params}
                  variant='outlined'
                  label={"Select " + props.label}
               />
            )}
         />
      </>
   );
}
