import React, { useEffect } from "react";

//HOOKS
import useGlobal from "hooks/useGlobal";

// MUI
import { Box } from "@mui/material";

import { useTheme } from "@mui/material/styles";

export default function Venue({ pk }) {
   const theme = useTheme();
   const model = "venues";

   const [
      venue,
      all,
      { getAll, get, updateModelData, add, remove },
      lastAdded,
      lastUpdated,
   ] = useGlobal();

   useEffect(() => {
      if (pk) {
         get(model, pk);
      }
   }, [pk]);

   return (
      <>
         {venue && (
            <>
               <Box
                  sx={{
                     ...theme.boxStyleInline,
                     pt: 0,
                     borderTop: "none",
                  }}
               >
                  <h5>Venue</h5>
                  {venue.venue}
                  <br />
                  {venue.address && (
                     <>
                        {venue.address}
                        <br />
                     </>
                  )}
                  {venue.city && (
                     <>
                        {venue.city}, {venue.state} {venue.zip}
                        <br />
                     </>
                  )}
                  {venue.country && venue.country}
               </Box>
               {venue.parking && (
                  <Box
                     sx={{
                        ...theme.boxStyleInline,
                     }}
                  >
                     <h5>Parking</h5>
                     {venue.parking}
                     <br />
                     {venue.parkingaddress}
                     <br />
                     {venue.parkingcity}, {venue.parkingstate}{" "}
                     {venue.parkingzip}
                     <br />
                     {venue.parkingcountry}
                  </Box>
               )}

               {venue.hospital && (
                  <Box
                     sx={{
                        ...theme.boxStyleInline,
                     }}
                  >
                     <h5>Hospital</h5>
                     {venue.hospital}
                     <br />
                     {venue.hospitaladdress}
                     <br />
                     {venue.hospitalcity}, {venue.hospitalstate}{" "}
                     {venue.hospitalzip}
                     <br />
                     {venue.hospitalcountry}
                     <br />
                     {venue.hospitalphone}
                  </Box>
               )}
            </>
         )}
      </>
   );
}
