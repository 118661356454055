import React, { useEffect } from "react";

//HOOKS
import useGlobal from "hooks/useGlobal";

// MUI
import { Box } from "@mui/material";

import { useTheme } from "@mui/material/styles";

export default function Company({ pk }) {
   const theme = useTheme();
   const model = "company";

   const [
      company,
      all,
      { getAll, get, updateModelData, add, remove },
      lastAdded,
      lastUpdated,
   ] = useGlobal();

   useEffect(() => {
      if (pk) {
         get(model, pk);
      }
   }, []);

   return (
      <>
         {company && (
            <>
               <Box
                  sx={{
                     ...theme.boxStyleInline,
                  }}
               >
                  <h5>{company.company}</h5>
                  {company.email && (
                     <>
                        {company.email}
                        <br />
                     </>
                  )}
                  {company.phone && (
                     <>
                        {company.phone}
                        <br />
                     </>
                  )}
                  {company.address && (
                     <>
                        {company.address}
                        <br />
                     </>
                  )}
                  {company.city && (
                     <>
                        {company.city}, {company.state} {company.zip}
                        <br />
                     </>
                  )}
                  {company.country && company.country}
               </Box>
            </>
         )}
      </>
   );
}
