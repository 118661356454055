import React, { useState, useContext } from "react";
import API from "handlers/API";

import { SocketContext } from "context/socket";

function useGlobal(init = {}) {
   const socket = useContext(SocketContext);

   const [state, setState] = useState(init);
   const [all, setAll] = useState({});
   const [lastAdded, setLastAdded] = useState();
   const [lastUpdated, setLastUpdated] = useState();

   const handlers = React.useMemo(
      () => ({
         getAll: (model, parentId = "") => {
            API.get("/api/" + model + "/getall/" + parentId).then((res) => {
               setAll((prevState) => ({ ...prevState, [model]: res.data }));
               setLastUpdated(Date.now());
               socket.emit("debug", { all: { [model]: res.data } });
            });
         },
         get: (model, id, column = "") => {
            API.get("/api/" + model + "/get/" + id + "/" + column).then(
               (res) => {
                  setState(res.data);
                  socket.emit("debug", { [model]: res.data });
               }
            );
         },
         updateModelData: async (data) => {
            const response = API.post("/api/global/save/", data).then((res) => {
               setLastUpdated(Date.now());
               socket.emit("debug", "Updated data: " + JSON.stringify(data));
               return res;
            });
            return response;
         },
         add: async (model, parentId = "", initialColumn, initialValue) => {
            let initialSet = initialColumn
               ? "/" + initialColumn + "/" + initialValue
               : "";

            const response = API.get(
               "/api/" + model + "/add/" + parentId + initialSet
            ).then((res) => {
               setLastAdded(res.data);
               socket.emit(
                  "debug",
                  "Added row to " + model + ". ID: " + JSON.stringify(res.data)
               );
               handlers.getAll(model, parentId);
               return res;
            });
            return response;
         },
         remove: (model, id, parentId = "") => {
            const response = API.get(
               "/api/" + model + "/delete/" + id + "/" + parentId
            ).then((res) => {
               setLastAdded(Date.now());
               socket.emit(
                  "debug",
                  "Removed data: " + JSON.stringify(res.data)
               );
               handlers.getAll(model, parentId);
               return res;
            });
            return response;
         },
         clearParent: () => {
            setState();
         },
         find: (model, id, column = "") => {
            API.get("/api/" + model + "/find/" + id + "/" + column).then(
               (res) => {
                  setAll((prevState) => ({ ...prevState, [model]: res.data }));
                  socket.emit("debug", { [model]: res.data });
               }
            );
         },
         // overview: (model, id) => {
         //    API.get("/api/" + model + "/overview/" + id).then((res) => {
         //       setState(res.data);
         //       socket.emit("debug", { [model]: res.data });
         //    });
         // },
      }),
      [init]
   );

   return [state, all, handlers, lastAdded, lastUpdated];
}

export default useGlobal;
