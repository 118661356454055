import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";

// MUI
import { Box, IconButton, Button, TextField, Stack } from "@mui/material";
import { Save, Cancel } from "@mui/icons-material";

import EditIcon from "images/icon-edit.svg";

export default function EditText({ model, pk, field, value, width, callback }) {
   const theme = useTheme();

   const [fieldValue, setFieldValue] = useState(value);
   const [editMode, setEditMode] = useState(false);

   const handleUpdateValue = (e) => {
      setFieldValue(e.target.value);
   };

   const handleEditMode = (e) => {
      setEditMode(!editMode);
   };

   const handleSave = () => {
      callback(fieldValue);
      setEditMode(!editMode);
   };

   return (
      <Stack
         direction='row'
         alignItems='center'
         justifyContent='flex-start'
         spacing={0}
         sx={{ position: "relative" }}
      >
         {editMode && (
            <>
               <TextField
                  value={fieldValue}
                  onChange={handleUpdateValue}
                  sx={{ position: "absolute", width: width }}
               />
               <Box
                  sx={{
                     position: "relative",
                     left: width + 10,
                     whiteSpace: "nowrap",
                  }}
               >
                  <IconButton
                     variant='contained'
                     color='secondary'
                     onClick={handleSave}
                  >
                     <Cancel />
                  </IconButton>
                  <IconButton
                     variant='contained'
                     color='error'
                     onClick={handleSave}
                  >
                     <Save />
                  </IconButton>
               </Box>
            </>
         )}
         {!editMode && (
            <>
               {fieldValue && <>{fieldValue}</>}
               {!fieldValue && <Box sx={{ opacity: 0.3 }}>No title</Box>}

               <Button onClick={handleEditMode} sx={{ ...theme.editButton }}>
                  <img src={EditIcon} />
               </Button>
            </>
         )}
      </Stack>
   );
}
