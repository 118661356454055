import React, {
   useState,
   useEffect,
} from "react";

//HOOKS
import useGlobal from "hooks/useGlobal";

// MUI
import {
   Button,
   Grid,
   TextField,
   Select,
   MenuItem,
} from "@mui/material";

function Roles() {
   const model = "roles";
   const [role, all, { getAll, get, updateModelData, add, remove }, lastAdded] =
      useGlobal();
   const [refresh, setRefresh] = useState();

   useEffect(() => {
      {
         /* getAll(model); */
      }
   }, []);

   useEffect(() => {
      getAll(model);
   }, [lastAdded, refresh]);

   const saveChange = (event, custom) => {
      let data = {
         ...custom,
         value: event.target.value,
      };

      updateModelData(data);
      setRefresh(Date.now());
   };

   const handleReload = (type = "", id = "") => {
      switch (type) {
         case "add":
            add(model);
            break;
         case "remove":
            remove(model);
            break;
         default:
      }

      setRefresh(Date.now());
   };

   const handleKeyPress = (e) => {
      if (e.key === "Enter") {
         e.target.blur();
      }
   };

   return (
      <div className='page'>
         <h2>
            Roles
            <Button
               variant='contained'
               color='success'
               style={{ float: "right" }}
               onClick={() => add(model)}
            >
               ADD ROLE
            </Button>
         </h2>
         <br />

         <Grid container xs={12} sm={6} sx={{ mx: "auto", mb: 4 }} spacing={1}>
            {all.roles &&
               all.roles.map((role) => (
                  <>
                     <Grid item xs={9} key={role.id}>
                        <TextField
                           variant='outlined'
                           margin='none'
                           fullWidth
                           defaultValue={role.title}
                           onBlur={(e) =>
                              saveChange(e, {
                                 model: model,
                                 pk: role.id,
                                 name: "title",
                              })
                           }
                           onKeyPress={handleKeyPress}
                        />
                     </Grid>
                     <Grid item xs={3}>
                        <Select
                           defaultValue={role.type}
                           label={"Type"}
                           fullWidth
                           onChange={(e) =>
                              saveChange(e, {
                                 model: model,
                                 pk: role.id,
                                 name: "type",
                              })
                           }
                           notched={false}
                           sx={{ height: "100%" }}
                        >
                           <MenuItem value='Crew'>Crew</MenuItem>
                           <MenuItem value='Lead'>Lead</MenuItem>
                           <MenuItem value='Talent'>Talent</MenuItem>
                        </Select>
                     </Grid>
                  </>
               ))}
         </Grid>
      </div>
   );
}

export default Roles;
