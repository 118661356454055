import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "services/auth.service";

import logoResources from "images/atomic-resources-logo.svg";

import PageWrapper from "components/wrappers/PageWrapper";

// MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

const Login = () => {
   const theme = useTheme();

   let navigate = useNavigate();

   const [username, setUsername] = useState("");
   const [password, setPassword] = useState("");
   const [loading, setLoading] = useState(false);
   const [message, setMessage] = useState("");

   const onChangeUsername = (e) => {
      const username = e.target.value;
      setUsername(username);
   };

   const onChangePassword = (e) => {
      const password = e.target.value;
      setPassword(password);
   };

   const handleLogin = (e) => {
      e.preventDefault();
      setMessage("");
      setLoading(true);

      AuthService.login(username, password).then(
         () => {
            navigate("/profile");
            window.location.reload();
         },
         (error) => {
            const resMessage =
               (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
               error.message ||
               error.toString();
            setLoading(false);
            setMessage(resMessage);
         }
      );
   };
   return (
      <PageWrapper>
         <Box sx={{ my: 6, mx: "auto", maxWidth: 400, width: "100%" }}>
            <img src={logoResources} alt='Atomic Resources' />

            <Box sx={{ mt: 4, width: "100%", ...theme.boxStyle }}>
               <Stack
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                  sx={{ my: 2 }}
                  spacing={1.5}
               >
                  <Box sx={{ mb: 1 }}>
                     <Typography
                        variant='h2'
                        component='h2'
                        sx={{ textAlign: "center" }}
                     >
                        Log In
                     </Typography>
                  </Box>

                  {message && (
                     <Typography variant='h6' component='h6' sx={{ my: 2 }}>
                        {message}
                     </Typography>
                  )}
                  <TextField
                     fullWidth
                     id='login'
                     label='Username'
                     value={username}
                     onChange={onChangeUsername}
                  />

                  <TextField
                     fullWidth
                     id='password'
                     label='Password'
                     variant='outlined'
                     value={password}
                     onChange={onChangePassword}
                     type='password'
                     autoComplete='current-password'
                  />

                  <Box sx={{ width: "100%", py: 2 }}>
                     <Button
                        variant='contained'
                        color='orange'
                        fullWidth
                        disabled={loading}
                        onClick={handleLogin}
                     >
                        {loading && (
                           <span className='spinner-border spinner-border-sm'></span>
                        )}
                        Log In
                     </Button>
                  </Box>
               </Stack>
            </Box>
         </Box>
      </PageWrapper>
   );
};
export default Login;
