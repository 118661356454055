import React, { useEffect } from "react";
import dateFormat from "dateformat";

import _ from "lodash";

//HOOKS
import useGlobal from "hooks/useGlobal";

// MUI
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function Staff({ projectId, roleType }) {
   const theme = useTheme();

   const model = "projectroles";

   const [
      projectrole,
      all,
      { getAll, get, updateModelData, add, remove, clearParent, find },
   ] = useGlobal();

   useEffect(() => {
      if (projectId) {
         getAll(model, projectId);
      }
      getAll("roles");
   }, []);

   const saveChange = (data) => {
      if (data.query) {
         console.log(JSON.parse(data.query));
         updateModelData(JSON.parse(data.query)).then((response) =>
            getAll(model, projectId)
         );
      }

      _.each(data, function (val, key) {
         if (val === "true") {
            data[key] = Boolean(val);
         }
      });
      updateModelData(data).then((response) => getAll(model, projectId));
   };

   const filterRoles = (roleType) => {
      const parsed = _.filter(all.projectroles, function (row) {
         if (row.role && row.role.type) {
            return row.role.type === roleType;
         } else {
            return "0";
         }
         return row.role.type === roleType;
      });

      if (parsed.length > 0) {
         return parsed;
      } else {
         return [{}];
      }
   };

   return (
      <Table layout='childTable'>
         <TableBody>
            {filterRoles(roleType).map((projectrole) => (
               <>
                  {!projectrole.projectroleperson && (
                     <TableRow>
                        <TableCell>No results.</TableCell>
                     </TableRow>
                  )}
                  {projectrole.projectroleperson &&
                     projectrole.projectroleperson.person && (
                        <TableRow>
                           <TableCell>
                              {projectrole.projectroleperson.person.first}{" "}
                              {projectrole.projectroleperson.person.last}
                           </TableCell>
                           <TableCell>{projectrole.role.title}</TableCell>
                           <TableCell>
                              {_.filter(
                                 projectrole.projectroleperson
                                    .projectpeopledates,
                                 { confirmed: 1 }
                              ).map((row, i) => (
                                 <>
                                    {i != 0 && <>, </>}
                                    {dateFormat(
                                       row.projectdate.date + "T00:00:00.000",
                                       "m/dd"
                                    )}
                                 </>
                              ))}
                           </TableCell>
                           <TableCell>
                              {projectrole.projectroleperson.person.phone}
                           </TableCell>
                           <TableCell>
                              {projectrole.projectroleperson.person.email}
                           </TableCell>
                        </TableRow>
                     )}
               </>
            ))}
         </TableBody>
      </Table>
   );
}
