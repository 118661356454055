import React, { useState, useEffect } from "react";
import API from "handlers/API";

export default function Reporting(props) {
   const [schedule, setSchedule] = useState([]);

   useEffect(() => {
      API.get("/people/schedule/" + props.id).then((res) => {
         setSchedule(res.data);
      });
   }, []);

   return (
      <div className='modalBlock'>
         <h3>Reporting</h3>
      </div>
   );
}
