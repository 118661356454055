import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// SOCKET
import { SocketContext, socket } from "context/socket.js";

// THEME
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

let theme = createTheme({
   shadows: ["none"],
   palette: {
      type: "dark",
      text: {
         primary: "#fff",
         secondary: "#B3B6BB",
         disabled: "#666",
         hint: "#f00",
      },
      primary: {
         main: "rgba(179, 182, 187, 1)",
      },
      secondary: {
         main: "#545A66",
         light: "rgba(236, 150, 8, 1)",
         dark: "rgba(236, 70, 8, 1)",
      },
      orange: {
         main: "rgba(236, 100, 8, 1)",
         light: "rgba(236, 150, 8, 1)",
         dark: "rgba(236, 70, 8, 1)",
      },
      success: {
         main: "#45BE6B",
      },
      active: {
         main: "#45BE6B",
      },
      onhold: {
         main: "#E8A30A",
      },
      quoted: {
         main: "#5891FF",
      },
      cancelled: {
         main: "#545A66",
      },
      production: {
         main: "#45BE6B",
      },
      studiorental: {
         main: "#B800D6",
      },
      equipmentrental: {
         main: "#E8A30A",
      },
   },
});

theme = createTheme(theme, {
   components: {
      MuiMenu: {
         styleOverrides: {
            root: {
               "& .MuiList-root": {
                  backgroundColor: "#fff",
                  "& .Mui-focusVisible": {
                     backgroundColor: "transparent",
                  },
               },
               "& .MuiMenuItem-root": {
                  color: "#000",
                  "&:hover": {
                     backgroundColor: "rgba(0,0,0,.1)",
                  },
               },
            },
         },
      },
      MuiPaper: {
         defaultProps: {
            sx: {
               padding: "10px",
               marginBottom: "10px",
            },
         },
      },
      MuiChip: {
         styleOverrides: {
            root: {
               padding: "1em",
               fontSize: ".6rem",
               fontWeight: 800,
               textTransform: "uppercase",
            },
         },
      },
      MuiToolbar: {
         variants: [
            {
               props: { variant: "headerBar" },
               style: {
                  height: 56,
                  minHeight: 56,
                  borderBottom: "1px solid #545A66",
               },
            },
         ],
      },
      MuiButton: {
         styleOverrides: {
            root: {
               fontWeight: "bold",
               color: "#000",
               fontStyle: "normal",
               fontSize: 12,
               letterSpacing: "0.05em",
               borderRadius: 10,
               py: 10,
               px: 14,
            },
         },
         variants: [
            {
               props: { color: "secondary" },
               style: {
                  color: "#fff",
               },
            },
            {
               props: { variant: "success" },
               style: {
                  color: "#000",
                  fontStyle: "normal",
                  fontWeight: 800,
                  fontSize: 15,
                  letterSpacing: "0.05em",
                  borderRadius: 10,
                  padding: 13,
               },
            },
            {
               props: { variant: "highlight" },
               style: {
                  color: "rgba(236, 100, 8, 1)",
                  fontStyle: "normal",
                  fontWeight: 800,
                  fontSize: 12,
                  letterSpacing: "0.05em",
               },
            },
            {
               props: { variant: "editicon" },
               style: {
                  color: "#fff",
                  padding: "1em",
               },
            },
            {
               props: { variant: "linkicon" },
               style: {
                  color: "#fff",
                  justifyContent: "flex-start",
                  gap: 10,
                  fontSize: 15,
                  padding: "1em",
                  marginLeft: "-1em",
                  marginRight: "-1em",
               },
            },
            {
               props: { variant: "inputField" },
               style: {
                  color: "#fff",
                  fontSize: "1rem",
                  lineHeight: "1rem",
                  border: "1px solid rgba(54, 61, 75, 1)",
                  borderRadius: 10,
                  backgroundColor: "rgba(12, 16, 26, 1)",
                  height: "100%",
               },
            },
         ],
      },
      MuiDialog: {
         styleOverrides: {
            root: {
               "& .MuiPaper-root": {
                  backgroundColor: "#0c101a",
               },
            },
         },
      },
      MuiTable: {
         variants: [
            {
               props: { layout: "masterTable" },
               style: {
                  borderCollapse: "separate",
                  borderSpacing: 0,
                  borderBottom: "none",
                  "& .MuiTableHead-root th": {
                     textTransform: "uppercase",
                     color: "rgba(179, 182, 187, 1)",
                  },
                  "& .MuiTableHead-root .MuiTableCell-root": {
                     borderBottom: "none",
                  },
                  "& .MuiTableFooter-root .MuiTableCell-root": {
                     borderBottom: "none",
                  },
                  "& .MuiTablePagination-toolbar .MuiButtonBase-root": {
                     color: "unset",
                  },
                  "& .MuiTablePagination-toolbar .MuiTablePagination-selectIcon ":
                     {
                        color: "unset",
                     },
                  "& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:first-child":
                     {
                        borderLeft: "1px solid #545A66",
                     },
                  "& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:last-child":
                     {
                        borderRight: "1px solid #545A66",
                     },
                  "& .MuiTableBody-root .MuiTableCell-root": {
                     borderTop: "1px solid #545A66",
                     borderBottom: "1px solid #545A66",
                     overflow: "hidden",
                     position: "relative",
                  },
                  "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd) .MuiTableCell-root":
                     {
                        backgroundColor: "#363D4B",
                     },
                  "& .MuiTableBody-root .MuiTableRow-root:nth-child(even)  .MuiTableCell-root":
                     {
                        backgroundColor: "#414755",
                     },
                  "& .MuiTableBody-root .MuiTableRow-root:first-child td:first-child":
                     {
                        borderTopLeftRadius: 10,
                     },
                  "& .MuiTableBody-root .MuiTableRow-root:first-child td:last-child":
                     {
                        borderTopRightRadius: 10,
                     },
                  "& .MuiTableBody-root .MuiTableRow-root:last-child td:first-child":
                     {
                        borderBottomLeftRadius: 10,
                     },
                  "& .MuiTableBody-root .MuiTableRow-root:last-child td:last-child":
                     {
                        borderBottomRightRadius: 10,
                     },
               },
            },
            {
               props: { layout: "childTable" },
               style: {
                  borderCollapse: "separate",
                  borderSpacing: 0,
                  borderBottom: "none",
                  "& .MuiTableHead-root th": {
                     textTransform: "uppercase",
                     color: "rgba(179, 182, 187, 1)",
                  },
                  "& .MuiTableHead-root .MuiTableCell-root": {
                     borderBottom: "none",
                  },
                  "& .MuiTableFooter-root .MuiTableCell-root": {
                     borderBottom: "none",
                  },
                  "& .MuiTablePagination-toolbar .MuiButtonBase-root": {
                     color: "unset",
                  },
                  "& .MuiTablePagination-toolbar .MuiTablePagination-selectIcon ":
                     {
                        color: "unset",
                     },
                  "& .MuiTableBody-root .MuiTableCell-root": {
                     borderTop: "1px solid #414755",
                     borderBottom: "none",
                     overflow: "hidden",
                     position: "relative",
                  },
                  "& .MuiTableRow-root .MuiTableCell-root:first-child": {
                     paddingLeft: "2rem",
                     fontWeight: "700",
                  },
               },
            },
         ],
      },
      MuiOutlinedInput: {
         styleOverrides: {
            root: {
               "& fieldset": {
                  borderRadius: 10,
                  borderColor: "rgba(54, 61, 75, 1)",
               },
               "&:hover fieldset": {
                  borderRadius: 10,
                  borderColor: "rgba(54, 61, 75, 1)",
               },
               "&.Mui-focused fieldset": {
                  borderRadius: 10,
                  borderColor: "rgba(236, 100, 8, 1)",
               },
            },
         },
      },
      MuiInputBase: {
         styleOverrides: {
            root: {
               "& .MuiOutlinedInput-input:-webkit-autofill": {
                  borderRadius: 10,
               },
               "& .MuiSelect-icon": {
                  fill: "#fff",
               },
            },
         },
      },
      MuiPopover: {
         styleOverrides: {
            root: {
               "& .MuiPopover-paper": {
                  color: "#333",
                  overflow: "visible",
               },
            },
         },
      },
      MuiAutocomplete: {
         styleOverrides: {
            popper: {
               "& .MuiAutocomplete-listbox": {
                  color: "#333",
               },
            },
            root: {
               "& .MuiFormControl-root": {
                  fontSize: "1rem",
                  lineHeight: "1rem",
                  borderRadius: 10,
                  backgroundColor: "rgba(12, 16, 26, 1)",
               },
               "& .MuiAutocomplete-endAdornment .MuiButtonBase-root": {
                  color: "#ccc",
                  "& :hover": {
                     color: "#ccc",
                  },
               },
               "& .MuiFormControl-root .MuiInputBase-input": {
                  border: "none",
                  "& fieldset": {
                     border: "none",
                  },
                  "& :hover fieldset": {
                     border: "none",
                  },
                  "& .Mui-focused fieldset": {
                     border: "none",
                  },
               },
            },
         },
      },
      MuiTextField: {
         styleOverrides: {
            root: {
               "& .MuiInputBase-input": {
                  fontSize: "1rem",
                  lineHeight: "1rem",
                  border: "1px solid rgba(54, 61, 75, 1)",
                  borderRadius: 10,
                  backgroundColor: "rgba(12, 16, 26, 1)",
               },
               "& .MuiInputBase-multiline": {
                  borderRadius: 10,
                  backgroundColor: "rgba(12, 16, 26, 1)",
               },
               "& .MuiInputBase-multiline .MuiInputBase-input": {
                  border: "none",
                  borderRadius: 0,
                  background: "none",
               },
               "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                     borderRadius: 10,
                     borderColor: "rgba(54, 61, 75, 1)",
                  },
                  "&:hover fieldset": {
                     borderRadius: 10,
                     borderColor: "rgba(54, 61, 75, 1)",
                  },
                  "&.Mui-focused fieldset": {
                     borderRadius: 10,
                     borderColor: "rgba(236, 100, 8, 1)",
                  },
               },
            },
         },
      },
      MuiBadge: {
         styleOverrides: {
            root: {
               "& .MuiBadge-badge": {
                  top: 0,
                  right: -10,
                  fontSize: ".4rem",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: theme.palette.primary.main,
                  color: theme.palette.primary.main,
                  backgroundColor: "transparent",
                  opacity: 0.7,
                  borderRadius: 4,
                  padding: 0,
                  minWidth: 15,
                  height: 15,
               },
            },
         },
      },
      MuiTabs: {
         styleOverrides: {
            root: {
               "& .Mui-selected": {
                  color: theme.palette.orange.main,
               },
               "& .MuiTabs-indicator": {
                  backgroundColor: theme.palette.orange.main,
               },
            },
         },
      },
   },
   typography: {
      fontFamily: ["Lato", "Arial", "sans-serif"].join(","),
      h1: {
         fontSize: "2rem",
         textTransform: "uppercase",
         fontStyle: "normal",
         fontWeight: 800,
         textAlign: "left",
         letterSpacing: "0.05em",
         marginTop: "1em",
         marginBottom: "1em",
      },
      h2: {
         fontSize: "1.6rem",
         textTransform: "uppercase",
         fontStyle: "normal",
         fontWeight: 800,
         textAlign: "left",
         letterSpacing: "0.05em",
         padding: 0,
         marginTop: 0,
         marginBottom: "1em",
      },
      h4: {
         fontSize: "1.3rem",
         fontStyle: "normal",
         fontWeight: 500,
         letterSpacing: "0.05em",
         color: theme.palette.text.primary,
         marginBottom: "1rem",
      },
      h5: {
         fontSize: ".9rem",
         fontStyle: "normal",
         fontWeight: 400,
         letterSpacing: "0.05em",
         color: theme.palette.text.secondary,
         marginBottom: "1rem",
      },
      h6: {
         fontSize: ".9rem",
         textTransform: "uppercase",
         fontStyle: "normal",
         fontWeight: 400,
         textAlign: "center",
         letterSpacing: "0.05em",
         color: "#f00",
      },
   },
   boxStyle: {
      position: "relative",
      boxSizing: "border-box",
      background: "rgba(32, 39, 55, 0.9)",
      border: "1px solid #545A66",
      borderRadius: "20px",
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 3,
      paddingRight: 3,
   },
   boxStyleInline: {
      position: "relative",
      boxSizing: "border-box",
      borderTop: "1px solid #545A66",
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 3,
      paddingRight: 3,
   },
   boxStyleParent: {
      position: "relative",
      boxSizing: "border-box",
      background: "rgba(32, 39, 55, 0.9)",
      borderRadius: "20px",
      paddingTop: 3,
      paddingBottom: 3,
      paddingLeft: 3,
      paddingRight: 3,
      marginBottom: 3,
   },
   boxStyleTransparent: {
      position: "relative",
      boxSizing: "border-box",
      background: "none",
      borderRadius: 0,
      paddingTop: 3,
      paddingBottom: 3,
      paddingLeft: 3,
      paddingRight: 3,
      marginBottom: 3,
   },
   editButton: {
      position: "relative",
      marginTop: -0.4,
      zIndex: 200,
   },
   editSectionButton: {
      position: "absolute",
      top: 14,
      right: 18,
      zIndex: 200,
   },
});

ReactDOM.render(
   <SocketContext.Provider value={socket}>
      <ThemeProvider theme={theme}>
         <CssBaseline />
         <BrowserRouter>
            <App />
         </BrowserRouter>
      </ThemeProvider>
   </SocketContext.Provider>,
   document.getElementById("root")
);
