import React, { useEffect } from "react";
import dateFormat from "dateformat";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//HOOKS
import useGlobal from "hooks/useGlobal";

// MUI
import { Grid, Box, Stack, Paper, TextField, IconButton } from "@mui/material";

import { styled } from "@mui/material/styles";
import { Delete } from "@mui/icons-material";

const Item = styled(Paper)(({ theme }) => ({
   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
   ...theme.typography.body2,
   padding: theme.spacing(1),
   color: theme.palette.text.secondary,
}));

export default function EditDates({ projectId }) {
   const model = "projectdates";
   const [row, all, { getAll, get, updateModelData, add, remove }] =
      useGlobal();

   const saveChange = (event) => {
      let id = event.target.getAttribute("id");
      let index = event.target.getAttribute("index");
      let name = event.target.getAttribute("name");
      let value = event.target.value;

      let data = {
         model: model,
         pk: id,
         refreshtype: "list",
         name: name,
         value: value,
      };

      updateModelData(data);
   };

   const saveDateChange = (id, index, name, value) => {
      switch (name) {
         case "date":
            value = dateFormat(value, "yyyy-mm-dd");
            break;
         case "starttime":
         case "endtime":
            value = dateFormat(value, "HH:MM:ss");
            break;
         default:
            return true;
      }
      let data = {
         refreshtype: "list",
         model: model,
         pk: id,
         name: name,
         value: value,
      };

      updateModelData(data);
   };

   const handleKeyPress = (e) => {
      if (e.key === "Enter") {
         e.target.blur();
      }
   };

   useEffect(() => {
      getAll(model, projectId);
   }, []);

   return (
      <>
         <h3>Schedule</h3>

         <Box sx={{ width: "100%" }}>
            {console.log(all.length)}
            <Stack spacing={2}>
               {all.projectdates &&
                  all.projectdates.map((schedule, i) => (
                     <Item key={"schedule" + schedule.id} className='listRow'>
                        <Grid
                           container
                           direction='row'
                           justifyContent='flex-start'
                           alignItems='center'
                        >
                           <Grid item xs={3}>
                              TITLE
                           </Grid>
                           <Grid item xs={9}>
                              <TextField
                                 size='small'
                                 id={schedule.id}
                                 index={i}
                                 placeholder='Description'
                                 name='description'
                                 defaultValue={
                                    schedule.description
                                       ? schedule.description
                                       : ""
                                 }
                                 onBlur={saveChange}
                                 onKeyPress={handleKeyPress}
                                 fullWidth
                              />
                           </Grid>

                           <Grid item xs={3}>
                              DATE
                           </Grid>
                           <Grid item xs={9}>
                              <DatePicker
                                 selected={
                                    schedule.date
                                       ? new Date(
                                            schedule.date +
                                               "T" +
                                               schedule.starttime
                                         )
                                       : ""
                                 }
                                 onChange={(date) =>
                                    saveDateChange(schedule.id, i, "date", date)
                                 }
                                 dateFormat='MMMM dd, yyyy'
                                 className='dateInput'
                              />
                           </Grid>

                           <Grid item xs={3}>
                              START
                           </Grid>
                           {schedule.date && (
                              <>
                                 <Grid item xs={9}>
                                    <DatePicker
                                       selected={
                                          schedule.starttime
                                             ? new Date(
                                                  schedule.date +
                                                     "T" +
                                                     schedule.starttime
                                               )
                                             : new Date()
                                       }
                                       onChange={(time) =>
                                          saveDateChange(
                                             schedule.id,
                                             i,
                                             "starttime",
                                             time
                                          )
                                       }
                                       showTimeSelect
                                       showTimeSelectOnly
                                       timeIntervals={15}
                                       timeCaption='Time'
                                       dateFormat='hh:mm aa'
                                       className='timeInput'
                                    />
                                 </Grid>

                                 <Grid item xs={3}>
                                    END
                                 </Grid>
                                 <Grid item xs={9}>
                                    <DatePicker
                                       selected={
                                          schedule.endtime
                                             ? new Date(
                                                  schedule.date +
                                                     "T" +
                                                     schedule.endtime
                                               )
                                             : new Date()
                                       }
                                       onChange={(time) =>
                                          saveDateChange(
                                             schedule.id,
                                             i,
                                             "endtime",
                                             time
                                          )
                                       }
                                       showTimeSelect
                                       showTimeSelectOnly
                                       timeIntervals={15}
                                       timeCaption='Time'
                                       dateFormat='hh:mm aa'
                                       className='timeInput'
                                    />
                                 </Grid>
                              </>
                           )}
                           <Grid item xs={12}>
                              <IconButton
                                 aria-label='delete'
                                 onClick={() =>
                                    remove(model, schedule.id, projectId)
                                 }
                              >
                                 <Delete />
                              </IconButton>
                           </Grid>
                        </Grid>
                     </Item>
                  ))}
            </Stack>
         </Box>
      </>
   );
}
