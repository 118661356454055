import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

//HOOKS
import useGlobal from "hooks/useGlobal";

import FadeIn from "components/wrappers/FadeIn";

//COMPONENTS
import Overview from "components/project/Overview";
import StaffTabs from "components/staff/StaffTabs";
import ScheduleTabs from "components/schedule/ScheduleTabs";

import EditDates from "components/project/EditDates";

// MUI
import { Box, Typography, Tabs, Tab } from "@mui/material";

function TabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role='tabpanel'
         hidden={value !== index}
         id={`full-width-tabpanel-${index}`}
         aria-labelledby={`full-width-tab-${index}`}
         {...other}
      >
         {value === index && (
            <FadeIn>
               <Box sx={{ p: 2 }}>
                  <Typography>{children}</Typography>
               </Box>
            </FadeIn>
         )}
      </div>
   );
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
};

function a11yProps(index) {
   return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
   };
}

export default function Project() {
   const { projectId } = useParams();

   const model = "projects";

   const [project, all, { getAll, get, updateModelData, add, remove }] =
      useGlobal();
   const [open, setOpen] = useState(true);
   const [tab, setTab] = useState(0);

   const handleTabChange = (event, newValue) => {
      setTab(newValue);
   };

   const handleKeyPress = (e) => {
      if (e.key === "Enter") {
         e.target.blur();
      }
   };

   const saveChange = (event, custom) => {
      let data = {
         ...custom,
         value: event.target.value,
      };

      updateModelData(data);
   };

   useEffect(() => {
      // if (projectId) {
      //    getAll("roles");
      //    getAll("programs");
      //    getAll("venues");
      //    getAll("company");
      // }
   }, [projectId]);

   useEffect(() => {
      // if (projectId) {
      //    get(model, projectId);
      // }
   }, [projectId]);

   return (
      <FadeIn>
         {projectId && (
            <>
               <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  aria-label='basic tabs example'
               >
                  <Tab label='Overview' {...a11yProps(0)} />
                  <Tab label='Staff' {...a11yProps(1)} />
                  {/* <Tab label='Crew' {...a11yProps(2)} />
                  <Tab label='Talent' {...a11yProps(3)} /> */}
                  <Tab label='Schedule' {...a11yProps(2)} />
                  {/* <Tab label='Edit Dates' {...a11yProps(3)} /> */}
               </Tabs>
               <TabPanel value={tab} index={0}>
                  <Overview projectId={projectId} />
               </TabPanel>
               <TabPanel value={tab} index={1}>
                  <StaffTabs projectId={projectId} />
               </TabPanel>
               <TabPanel value={tab} index={2}>
                  <ScheduleTabs projectId={projectId} />
               </TabPanel>
               {/* <TabPanel value={tab} index={3}>
                  <EditDates projectId={projectId} />
               </TabPanel> */}
               {/* <TabPanel value={tab} index={1}>
                  <Leads
                     lastLoad={refresh}
                     projectId={projectId}
                     refresh={handleRefresh}
                     allroles={_.filter(all.roles, function (o) {
                        return o.type == "Lead";
                     })}
                  />
               </TabPanel>
               <TabPanel value={tab} index={2}>
                  <Crew
                     lastLoad={refresh}
                     projectId={projectId}
                     refresh={handleRefresh}
                     allroles={_.filter(all.roles, function (o) {
                        return o.type == "Crew";
                     })}
                  />
               </TabPanel>
               <TabPanel value={tab} index={3}>
                  <Talent
                     lastLoad={refresh}
                     projectId={projectId}
                     refresh={handleRefresh}
                     allroles={_.filter(all.roles, function (o) {
                        return o.type == "Talent";
                     })}
                  />
               </TabPanel> */}
            </>
         )}
      </FadeIn>
   );
}

{
   /* <Box sx={{ mt: 4 }}>
<Grid container spacing={4}>
   <Grid
      item
      sx={{
         width: 400,
         px: 3,
         pb: 6,
         backgroundColor: "#efefef",
      }}
   >
      <TextField
         variant='filled'
         fullWidth
         label='Project'
         defaultValue={project.project ? project.project : ""}
         onBlur={(e) =>
            saveChange(e, {
               model: model,
               pk: project.id,
               name: "project",
            })
         }
         onKeyPress={handleKeyPress}
         sx={{ mb: 4 }}
      />

      <h5>PROGRAM</h5>
      <Select
         variant='filled'
         defaultValue={project.programId}
         onChange={(e) =>
            saveChange(e, {
               model: model,
               pk: project.id,
               name: "programId",
            })
         }
         fullWidth
      >
         <MenuItem value={null}>--</MenuItem>
         {all.programs &&
            all.programs.map((row) => (
               <MenuItem
                  selected={project.programId == row.id ? 1 : 0}
                  key={"program" + row.id}
                  value={row.id}
               >
                  {row.program}
               </MenuItem>
            ))}
      </Select>

      <h5>CLIENT</h5>
      <Select
         variant='filled'
         defaultValue={project.companyId}
         onChange={(e) =>
            saveChange(e, {
               model: model,
               pk: project.id,
               name: "companyId",
            })
         }
         fullWidth
      >
         <MenuItem value={null}>--</MenuItem>
         {all.company &&
            all.company.map((row, i) => (
               <MenuItem
                  selected={project.companyId == row.id ? 1 : 0}
                  key={"company" + row.id}
                  value={row.id}
               >
                  {row.company}
               </MenuItem>
            ))}
      </Select>

      <h5>VENUE</h5>
      <Select
         variant='filled'
         name='venueId'
         defaultValue={project.venueId}
         onChange={(e) =>
            saveChange(e, {
               model: model,
               pk: project.id,
               name: "venueId",
            })
         }
         fullWidth
         sx={{ mb: 2 }}
      >
         <MenuItem value={null}>--</MenuItem>
         {all.venues &&
            all.venues.map((row, i) => (
               <MenuItem
                  selected={project.venueId == row.id ? 1 : 0}
                  key={"venue" + row.id}
                  value={row.id}
               >
                  {row.venue}
               </MenuItem>
            ))}
      </Select>

      <EditDates
         lastLoad={refresh}
         projectId={project.id}
         refresh={handleRefresh}
      />

      <h3>Links</h3>

      <TextField
         variant='filled'
         fullWidth
         label='Run of Show Link'
         defaultValue={project.roslink ? project.roslink : ""}
         onBlur={(e) =>
            saveChange(e, {
               model: model,
               pk: project.id,
               name: "roslink",
            })
         }
         onKeyPress={handleKeyPress}
      />

      <TextField
         variant='filled'
         fullWidth
         label='Video Link'
         defaultValue={project.vodlink ? project.vodlink : ""}
         onBlur={(e) =>
            saveChange(e, {
               model: model,
               pk: project.id,
               name: "vodlink",
            })
         }
         onKeyPress={handleKeyPress}
      />
      <TextField
         variant='filled'
         fullWidth
         label='Sizzle Link'
         defaultValue={
            project.sizzlelink ? project.sizzlelink : ""
         }
         onBlur={(e) =>
            saveChange(e, {
               model: model,
               pk: project.id,
               name: "sizzlelink",
            })
         }
         onKeyPress={handleKeyPress}
      />

      <TextField
         variant='filled'
         fullWidth
         label='Script Link'
         defaultValue={
            project.scriptlink ? project.scriptlink : ""
         }
         onBlur={(e) =>
            saveChange(e, {
               model: model,
               pk: project.id,
               name: "scriptlink",
            })
         }
         onKeyPress={handleKeyPress}
      />
   </Grid>
   <Grid item xs>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}> */
}
