import React, { useEffect } from "react";

//HOOKS
import useGlobal from "hooks/useGlobal";

import { useTheme } from "@mui/material/styles";

export default function Program({ pk }) {
   const theme = useTheme();
   const model = "programs";

   const [
      program,
      all,
      { getAll, get, updateModelData, add, remove },
      lastAdded,
      lastUpdated,
   ] = useGlobal();

   useEffect(() => {
      if (pk) {
         get(model, pk);
      }
   }, []);

   return (
      <>
         {program && (
            <>
               {program.program}
               {program.logo && (
                  <img
                     src={"data:image/jpeg;base64, " + program.logo}
                     style={{
                        width: 100,
                        margin: 20,
                        borderRadius: "50%",
                     }}
                  />
               )}
            </>
         )}
      </>
   );
}
