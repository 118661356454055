import React, { useState, useRef, useEffect, useCallback } from "react";

import Cropper from "react-easy-crop";
import getCroppedImg from "components/widgets/CropImage";
import FileUpload from "react-mui-fileuploader";

// MUI
import { Box, Button } from "@mui/material";

export default function ImageUpload(props) {
   const imgRef = useRef(null);

   const [crop, setCrop] = useState({ x: 0, y: 0 });
   const [zoom, setZoom] = useState(1);
   const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
   const [croppedImage, setCroppedImage] = useState(null);
   const [upImg, setUpImg] = useState();

   const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
   }, []);

   const showCroppedImage = useCallback(async () => {
      try {
         const croppedImage = await getCroppedImg(upImg, croppedAreaPixels, 0);
         setCroppedImage(croppedImage);
      } catch (e) {
         console.error(e);
      }
   }, [croppedAreaPixels]);

   const handleFileChange = (files) => {
      if (files && files.length > 0) {
         setUpImg(files[0].path);
      }
   };

   const onLoad = useCallback((img) => {
      imgRef.current = img;
   }, []);

   useEffect(() => {
      if (croppedImage) {
         props.saveImage(croppedImage);
      }
   }, [croppedImage]);

   return (
      <>
         {!upImg && (
            <FileUpload
               title=''
               multiFile={true}
               disabled={false}
               header='Drag & Drop'
               leftLabel='or'
               rightLabel=''
               buttonLabel='click here'
               imageSrc={""}
               maxFileSize={10}
               maxUploadFiles={0}
               maxFilesContainerHeight={450}
               errorSizeMessage={""}
               allowedExtensions={["jpg", "jpeg", "png", "gif"]}
               onFilesChange={handleFileChange}
               bannerProps={{ elevation: 0, variant: "outlined" }}
               containerProps={{ elevation: 0, variant: "outlined" }}
            />
         )}
         {upImg && (
            <>
               <Box
                  sx={{
                     position: "relative",
                     width: 300,
                     height: 300,
                     border: "1px solid #000",
                  }}
               >
                  <Cropper
                     image={upImg}
                     crop={crop}
                     zoom={zoom}
                     aspect={1 / 1}
                     onCropChange={setCrop}
                     onCropComplete={onCropComplete}
                     onZoomChange={setZoom}
                  />
               </Box>

               <Button
                  onClick={showCroppedImage}
                  variant='contained'
                  color='success'
                  sx={{
                     my: 2,
                  }}
               >
                  SAVE
               </Button>
            </>
         )}
      </>
   );
}
