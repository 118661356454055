import Box from "@mui/material/Box";
import { motion } from "framer-motion";

export default function PageWrapper({ children, width }) {
   return (
      <Box
         component={motion.div}
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         sx={{
            width: "100%",
            padding: "1em",
            boxSizing: "border-box",
         }}
      >
         {children}
      </Box>
   );
}
