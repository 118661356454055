import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PageWrapper from "components/wrappers/PageWrapper";

//HOOKS
import useGlobal from "hooks/useGlobal";

//MODAL
import placeholder from "images/profile_placeholder.jpg";

// MUI
import {
   Button,
   IconButton,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableFooter,
   TableRow,
   TablePagination,
   Box,
   Typography,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
   LastPage,
   FirstPage,
   KeyboardArrowRight,
   KeyboardArrowLeft,
} from "@mui/icons-material";

function TablePaginationActions(props) {
   const theme = useTheme();

   const { count, page, rowsPerPage, onPageChange } = props;

   const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
   };

   const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
   };

   const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
   };

   const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
   };

   return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
         <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label='first page'
         >
            {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
         </IconButton>
         <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label='previous page'
         >
            {theme.direction === "rtl" ? (
               <KeyboardArrowRight />
            ) : (
               <KeyboardArrowLeft />
            )}
         </IconButton>
         <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label='next page'
         >
            {theme.direction === "rtl" ? (
               <KeyboardArrowLeft />
            ) : (
               <KeyboardArrowRight />
            )}
         </IconButton>
         <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label='last page'
         >
            {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
         </IconButton>
      </Box>
   );
}

TablePaginationActions.propTypes = {
   count: PropTypes.number.isRequired,
   onPageChange: PropTypes.func.isRequired,
   page: PropTypes.number.isRequired,
   rowsPerPage: PropTypes.number.isRequired,
};

function People() {
   const navigate = useNavigate();
   const theme = useTheme();

   const model = "people";
   const [
      person,
      all,
      { getAll, get, updateModelData, add, remove },
      lastAdded,
   ] = useGlobal();
   const [modalId, setModalId] = useState();

   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(5);

   // Avoid a layout jump when reaching the last page with empty rows.
   const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - all.people.length) : 0;

   const handleChangePage = (event, newPage) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };

   useEffect(() => {
      {
         /* getAll(model); */
      }
   }, []);

   useEffect(() => {
      setModalId(lastAdded);
      getAll(model);
   }, [lastAdded]);

   const closePerson = () => {
      setModalId();
      getAll(model);
   };

   return (
      <PageWrapper>
         <Box sx={{ my: 6, width: "100%" }}>
            <Typography variant='h2' component='h2'>
               People
               <Button
                  variant='contained'
                  color='success'
                  style={{ float: "right" }}
                  onClick={() => add(model)}
               >
                  ADD PERSON
               </Button>
            </Typography>

            <TableContainer sx={{ mt: 4 }}>
               <Table layout='masterTable'>
                  <TableHead>
                     <TableRow>
                        <TableCell></TableCell>
                        <TableCell>First</TableCell>
                        <TableCell>Last</TableCell>
                        <TableCell>Roles</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Employment</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell></TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {all.people && (
                        <>
                           {(rowsPerPage > 0
                              ? all.people.slice(
                                   page * rowsPerPage,
                                   page * rowsPerPage + rowsPerPage
                                )
                              : all.people
                           ).map((person) => (
                              <TableRow
                                 key={"person" + person.id}
                                 sx={{
                                    "&:last-child td, &:last-child th": {
                                       border: 0,
                                    },
                                 }}
                              >
                                 <TableCell
                                    sx={{
                                       width: 100,
                                       minWidth: 100,
                                       p: 0,
                                       position: "relative",
                                    }}
                                 >
                                    <img
                                       src={
                                          person.image
                                             ? "data:image/jpeg;base64, " +
                                               person.image
                                             : placeholder
                                       }
                                       style={{
                                          width: 80,
                                          margin: 20,
                                          borderRadius: "50%",
                                       }}
                                    />
                                 </TableCell>
                                 <TableCell>{person.first}</TableCell>
                                 <TableCell>{person.last}</TableCell>
                                 <TableCell>
                                    {person.peopleroles &&
                                       person.peopleroles.map((row, o) => (
                                          <>
                                             {row.id && (
                                                <Box
                                                   sx={{
                                                      display: "inline-block",
                                                      mr: 1,
                                                      whiteSpace: "nowrap",
                                                   }}
                                                >
                                                   {row.role.title}
                                                   {o + 1 <
                                                      person.peopleroles
                                                         .length && <>, </>}
                                                </Box>
                                             )}
                                          </>
                                       ))}
                                 </TableCell>
                                 <TableCell>{person.phone}</TableCell>
                                 <TableCell>
                                    {person.employmentstatus}
                                 </TableCell>
                                 <TableCell>{person.location}</TableCell>
                                 <TableCell align='right'>
                                    <Button
                                       variant='contained'
                                       color='secondary'
                                       onClick={() => {
                                          navigate("/people/" + person.id);
                                       }}
                                    >
                                       VIEW
                                    </Button>
                                 </TableCell>
                              </TableRow>
                           ))}

                           {emptyRows > 0 && (
                              <TableRow style={{ height: 53 * emptyRows }}>
                                 <TableCell colSpan={8} />
                              </TableRow>
                           )}
                        </>
                     )}
                  </TableBody>
                  <TableFooter>
                     <TableRow>
                        <TablePagination
                           rowsPerPageOptions={[
                              5,
                              10,
                              25,
                              { label: "All", value: -1 },
                           ]}
                           colSpan={8}
                           count={all.people && all.people.length}
                           rowsPerPage={rowsPerPage}
                           page={page}
                           SelectProps={{
                              inputProps: {
                                 "aria-label": "rows per page",
                              },
                              native: true,
                           }}
                           onPageChange={handleChangePage}
                           onRowsPerPageChange={handleChangeRowsPerPage}
                           ActionsComponent={TablePaginationActions}
                        />
                     </TableRow>
                  </TableFooter>
               </Table>
            </TableContainer>
         </Box>
      </PageWrapper>
   );
}

export default People;
