import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";

// MUI
import { Box, IconButton, Button, TextField, Stack } from "@mui/material";
import { Save, Cancel } from "@mui/icons-material";

import EditIcon from "images/icon-edit.svg";

// DATE
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function EditDate({ value, width, callback }) {
   const theme = useTheme();

   const [fieldValue, setFieldValue] = useState(value);
   const [editMode, setEditMode] = useState(false);

   const handleUpdateValue = (date) => {
      const updatedValue = dateFormat(date, "yyyy-mm-dd");
      setFieldValue(updatedValue);
   };

   const handleEditMode = (e) => {
      setEditMode(!editMode);
   };

   const handleCancel = () => {
      setFieldValue(value);
      setEditMode(!editMode);
   };

   const handleSave = () => {
      callback(fieldValue);
      setEditMode(!editMode);
   };

   return (
      <Stack
         direction='row'
         alignItems='center'
         justifyContent='flex-start'
         spacing={0}
         sx={{ position: "relative", width: width }}
      >
         {editMode && (
            <>
               <DatePicker
                  selected={
                     fieldValue ? new Date(fieldValue + "T08:00:00") : ""
                  }
                  onChange={handleUpdateValue}
                  dateFormat='MMMM dd, yyyy'
                  className='dateInput'
               />
               <Box
                  sx={{
                     position: "relative",
                     whiteSpace: "nowrap",
                  }}
               >
                  <IconButton
                     variant='contained'
                     color='secondary'
                     onClick={handleCancel}
                  >
                     <Cancel />
                  </IconButton>
                  <IconButton
                     variant='contained'
                     color='error'
                     onClick={handleSave}
                  >
                     <Save />
                  </IconButton>
               </Box>
            </>
         )}
         {!editMode && (
            <>
               <b>{fieldValue}</b>
               <Button onClick={handleEditMode} sx={{ ...theme.editButton }}>
                  <img src={EditIcon} />
               </Button>
            </>
         )}
      </Stack>
   );
}
