import React, { useState, useEffect } from "react";

//HOOKS
import useGlobal from "hooks/useGlobal";

import ImageUpload from "components/widgets/ImageUpload";
import placeholder from "images/profile_placeholder.jpg";

// MUI
import {
   Box,
   Button,
   Dialog,
   Stack,
   DialogContent,
   Slide,
   Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction='up' ref={ref} {...props} />;
});

export default function PersonImage(props) {
   const model = "people";
   const [
      person,
      all,
      { getAll, get, updateModelData, add, remove },
      lastAdded,
      lastUpdated,
   ] = useGlobal();
   const [open, setOpen] = useState(false);

   useEffect(() => {
      if (props.personId) {
         get(model, props.personId, "image");
      }
   }, [props.personId, lastUpdated]);

   const handleImage = (image) => {
      let name = "image";
      let value = image;
      let data = { model: model, pk: person.id, name: name, value: value };

      updateModelData(data);
      setOpen(false);
   };

   return (
      <>
         {person && (
            <>
               <img
                  src={
                     person.image
                        ? "data:image/jpeg;base64, " + person.image
                        : placeholder
                  }
                  style={{
                     width: "100%",
                     marginBottom: "2em",
                     borderRadius: "50%",
                  }}
                  onClick={() => setOpen(true)}
               />

               <Dialog open={open} TransitionComponent={Transition}>
                  <Stack
                     direction='row'
                     alignItems='center'
                     sx={{ p: 4, pr: 2, width: 500 }}
                  >
                     <Typography
                        variant='h2'
                        component='div'
                        sx={{ mb: 0, width: "100%" }}
                     >
                        Upload Image
                     </Typography>
                     <Button
                        size='small'
                        color='secondary'
                        onClick={() => setOpen(false)}
                     >
                        <Close />
                     </Button>
                  </Stack>
                  <Stack alignItems='center'>
                     <ImageUpload saveImage={handleImage} />
                  </Stack>
               </Dialog>
            </>
         )}
      </>
   );
}
