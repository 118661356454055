import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import _ from "lodash";

import FadeIn from "components/wrappers/FadeIn";
import Staff from "components/staff/Staff";
import StaffOverview from "components/staff/StaffOverview";

//HOOKS
import useGlobal from "hooks/useGlobal";

// MUI
import { Badge, Box, Typography, Tabs, Tab } from "@mui/material";

function TabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role='tabpanel'
         hidden={value !== index}
         id={`people-tabpanel-${index}`}
         aria-labelledby={`people-tab-${index}`}
         {...other}
      >
         {value === index && (
            <FadeIn>
               <Box>
                  <Typography>{children}</Typography>
               </Box>
            </FadeIn>
         )}
      </div>
   );
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
};

function a11yProps(index) {
   return {
      id: `people-tab-${index}`,
      "aria-controls": `people-tabpanel-${index}`,
   };
}

export default function StaffTabs({ projectId, parent = "" }) {
   const model = "projectroles";

   const [tab, setTab] = useState(0);

   const roleTypes = ["Lead", "Talent", "Crew"];

   const handleRefresh = () => {
      // console.log("refresh!", projectId, model);
      if (projectId) {
         getAll(model, projectId);
      }
   };

   const [
      projectroles,
      all,
      { getAll, get, updateModelData, add, remove, clearParent, find },
   ] = useGlobal();

   useEffect(() => {
      if (projectId) {
         getAll(model, projectId);
      }
   }, []);

   useEffect(() => {
      // console.log(all);
   }, [all]);

   return (
      <>
         <Box sx={{ borderBottom: 1, borderColor: "#414755", px: 4 }}>
            <Tabs value={tab}>
               {all.projectroles &&
                  roleTypes.map((roleType, i) => (
                     <>
                        <Tab
                           onClick={() => setTab(i)}
                           label={
                              <Badge
                                 badgeContent={String(
                                    _.filter(all.projectroles, function (row) {
                                       if (row.role && row.role.type) {
                                          return row.role.type == roleType;
                                       } else {
                                          return "0";
                                       }
                                    }).length
                                 )}
                                 color='primary'
                              >
                                 {roleType}
                              </Badge>
                           }
                           {...a11yProps(i)}
                        />
                     </>
                  ))}
            </Tabs>
         </Box>
         {all.projectroles &&
            roleTypes.map((roleType, i) => (
               <TabPanel value={tab} index={i}>
                  {parent == "overview" ? (
                     <StaffOverview
                        projectId={projectId}
                        key={"scheduleTab" + i}
                        roleType={roleType}
                     />
                  ) : (
                     <Staff
                        projectId={projectId}
                        key={"scheduleTab" + i}
                        roleType={roleType}
                        changeTab={setTab}
                        refresh={handleRefresh}
                     />
                  )}
               </TabPanel>
            ))}
      </>
   );
}
