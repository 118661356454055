import React, { useEffect } from "react";

import FadeIn from "components/wrappers/FadeIn";

//HOOKS
import useGlobal from "hooks/useGlobal";

// MUI
import { Typography, TextField } from "@mui/material";

import { useTheme } from "@mui/material/styles";

export default function OverviewInformation({ project, editMode, refresh }) {
   const theme = useTheme();

   const [
      programs,
      all,
      { getAll, get, updateModelData, add, remove, clearParent, find },
   ] = useGlobal();

   useEffect(() => {
      getAll("programs");
   }, []);

   const saveChange = (event, custom) => {
      let data = {
         ...custom,
         value: event.target.value,
      };

      updateModelData(data).then((response) => refresh());
   };

   const handleKeyPress = (e) => {
      if (e.key === "Enter") {
         e.target.blur();
      }
   };

   return (
      <>
         <Typography variant='h5' component='div'>
            Information & Instructions
         </Typography>
         {editMode && (
            <FadeIn>
               <TextField
                  multiline
                  variant='outlined'
                  defaultValue={"Not active yet"}
                  rows={6}
                  fullWidth
               />
            </FadeIn>
         )}

         {!editMode && <FadeIn>Text here</FadeIn>}
      </>
   );
}
