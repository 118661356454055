import React, { useEffect } from "react";

import dateFormat from "dateformat";

import ChipWrapper from "components/wrappers/ChipWrapper";
import FadeIn from "components/wrappers/FadeIn";

// BLOCKS
import Program from "components/project/Program";

//HOOKS
import useGlobal from "hooks/useGlobal";

// MUI
import {
   Box,
   Typography,
   FormControl,
   InputLabel,
   Select,
   MenuItem,
   TextField,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

export default function OverviewProject({ project, editMode, refresh }) {
   const theme = useTheme();

   const [
      programs,
      all,
      { getAll, get, updateModelData, add, remove, clearParent, find },
   ] = useGlobal();

   useEffect(() => {
      getAll("programs");
   }, []);

   const handleKeyPress = (e) => {
      if (e.key === "Enter") {
         e.target.blur();
      }
   };

   return (
      <>
         {editMode && (
            <FadeIn>
               <Typography variant='h5' component='div'>
                  <FormControl sx={{ minWidth: 400 }}>
                     <InputLabel id='programLabel'>Program</InputLabel>
                     <Select
                        labelId='programLabel'
                        id='programInput'
                        label='Program'
                        variant='outlined'
                        defaultValue={project.programId}
                        onChange={(e) =>
                           updateModelData({
                              model: "projects",
                              pk: project.id,
                              name: "programId",
                              value: e.target.value,
                           }).then((response) => refresh())
                        }
                     >
                        <MenuItem value={null}>--</MenuItem>
                        {all.programs &&
                           all.programs.map((row) => (
                              <MenuItem
                                 selected={project.programId === row.id ? 1 : 0}
                                 key={"program" + row.id}
                                 value={row.id}
                              >
                                 {row.program}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               </Typography>
               <Typography variant='h4' component='div'>
                  <TextField
                     variant='outlined'
                     label='Project'
                     defaultValue={project.project ? project.project : ""}
                     onBlur={(e) =>
                        updateModelData({
                           model: "projects",
                           pk: project.id,
                           name: "project",
                           value: e.target.value,
                        }).then((response) => refresh())
                     }
                     onKeyPress={handleKeyPress}
                     sx={{ minWidth: 500 }}
                  />
               </Typography>

               <ChipWrapper label={project.status} size='large' />
               <ChipWrapper label={project.type} size='large' />

               <TextField
                  label='Description'
                  multiline
                  variant='outlined'
                  defaultValue={project.description ? project.description : ""}
                  rows={6}
                  fullWidth
                  onBlur={(e) =>
                     updateModelData({
                        model: "projects",
                        pk: project.id,
                        name: "description",
                        value: e.target.value,
                     }).then((response) => refresh())
                  }
                  sx={{ mt: 3 }}
               />
            </FadeIn>
         )}

         {!editMode && (
            <FadeIn>
               <Typography variant='h5' component='div'>
                  {project.programId && <Program pk={project.programId} />}
               </Typography>
               <Typography variant='h4' component='div'>
                  {project.project}
               </Typography>
               <ChipWrapper label={project.status} size='large' />
               <ChipWrapper label={project.type} size='large' />
               <Box sx={{ mt: 1, mb: 2, fontWeight: 700 }}>
                  {project.projectdates &&
                     project.projectdates.map((row, i) => (
                        <>
                           {row.date && (
                              <>
                                 {dateFormat(
                                    row.date + "T00:00:00.000",
                                    "m/dd"
                                 )}
                                 {project.projectdates.length !== i + 1 && (
                                    <>, </>
                                 )}
                              </>
                           )}
                        </>
                     ))}
               </Box>
               <Box sx={{ color: theme.palette.text.secondary }}>
                  {project.description}
               </Box>
            </FadeIn>
         )}
      </>
   );
}
