import React, { useEffect } from "react";

import FadeIn from "components/wrappers/FadeIn";
import BirthdayIcon from "images/icon-dob.svg";

//HOOKS
import useGlobal from "hooks/useGlobal";

// MUI
import { Box, Typography, TextField, Grid, Chip, Stack } from "@mui/material";

import { useTheme } from "@mui/material/styles";

export default function PersonDetails({ person, editMode, refresh }) {
   const theme = useTheme();

   const [
      single,
      all,
      { getAll, get, updateModelData, add, remove, clearParent, find },
   ] = useGlobal();

   const saveChange = (event, custom) => {
      let data = {
         ...custom,
         value: event.target.value,
      };

      updateModelData(data).then((response) => refresh());
   };

   const handleKeyPress = (e) => {
      if (e.key === "Enter") {
         e.target.blur();
      }
   };

   return (
      <>
         {editMode && (
            <FadeIn>
               <Typography variant='h2' component='div'>
                  EDIT CONTACT
               </Typography>
               <Grid container spacing={3}>
                  <Grid item xs={12}>
                     <Stack spacing={2}>
                        <TextField
                           variant='outlined'
                           margin='none'
                           fullWidth
                           label='First Name'
                           defaultValue={person.first}
                           onBlur={(e) =>
                              updateModelData({
                                 model: "people",
                                 pk: person.id,
                                 name: "first",
                                 value: e.target.value,
                              }).then((response) => refresh())
                           }
                           onKeyPress={handleKeyPress}
                        />
                        <TextField
                           variant='outlined'
                           margin='none'
                           fullWidth
                           label='Last Name'
                           defaultValue={person.last}
                           onBlur={(e) =>
                              updateModelData({
                                 model: "people",
                                 pk: person.id,
                                 name: "last",
                                 value: e.target.value,
                              }).then((response) => refresh())
                           }
                           onKeyPress={handleKeyPress}
                        />
                     </Stack>
                  </Grid>
                  <Grid item xs={12}>
                     <Chip
                        label='Contractor Type Here'
                        color='error'
                        sx={{
                           fontSize: ".8rem",
                           mb: 1,
                        }}
                     />
                  </Grid>
                  <Grid item xs={6}>
                     <Stack spacing={2}>
                        <TextField
                           variant='outlined'
                           margin='none'
                           fullWidth
                           label='Location'
                           defaultValue={person.location}
                           onBlur={(e) =>
                              updateModelData({
                                 model: "people",
                                 pk: person.id,
                                 name: "location",
                                 value: e.target.value,
                              }).then((response) => refresh())
                           }
                           onKeyPress={handleKeyPress}
                        />
                        <TextField
                           label='Phone'
                           variant='outlined'
                           margin='none'
                           fullWidth
                           defaultValue={person.phone}
                           onBlur={(e) =>
                              updateModelData({
                                 model: "people",
                                 pk: person.id,
                                 name: "phone",
                                 value: e.target.value,
                              }).then((response) => refresh())
                           }
                           onKeyPress={handleKeyPress}
                        />

                        <TextField
                           label='Email'
                           variant='outlined'
                           fullWidth
                           defaultValue={person.email}
                           onBlur={(e) =>
                              updateModelData({
                                 model: "people",
                                 pk: person.id,
                                 name: "email",
                                 value: e.target.value,
                              }).then((response) => refresh())
                           }
                           onKeyPress={handleKeyPress}
                        />
                     </Stack>
                  </Grid>
                  <Grid item xs={6}>
                     <Stack spacing={2}>
                        <TextField
                           variant='outlined'
                           margin='none'
                           fullWidth
                           label='Address'
                           defaultValue={person.address}
                           onBlur={(e) =>
                              updateModelData({
                                 model: "people",
                                 pk: person.id,
                                 name: "address",
                                 value: e.target.value,
                              }).then((response) => refresh())
                           }
                           onKeyPress={handleKeyPress}
                        />
                        <TextField
                           variant='outlined'
                           margin='none'
                           fullWidth
                           label='City'
                           defaultValue={person.city}
                           onBlur={(e) =>
                              updateModelData({
                                 model: "people",
                                 pk: person.id,
                                 name: "city",
                                 value: e.target.value,
                              }).then((response) => refresh())
                           }
                           onKeyPress={handleKeyPress}
                        />
                        <TextField
                           variant='outlined'
                           margin='none'
                           fullWidth
                           label='State'
                           defaultValue={person.state}
                           onBlur={(e) =>
                              updateModelData({
                                 model: "people",
                                 pk: person.id,
                                 name: "state",
                                 value: e.target.value,
                              }).then((response) => refresh())
                           }
                           onKeyPress={handleKeyPress}
                        />
                        <TextField
                           variant='outlined'
                           margin='none'
                           fullWidth
                           label='ZIP'
                           defaultValue={person.zip}
                           onBlur={(e) =>
                              updateModelData({
                                 model: "people",
                                 pk: person.id,
                                 name: "zip",
                                 value: e.target.value,
                              }).then((response) => refresh())
                           }
                           onKeyPress={handleKeyPress}
                        />
                        <TextField
                           variant='outlined'
                           margin='none'
                           fullWidth
                           label='Country'
                           defaultValue={person.country}
                           onBlur={(e) =>
                              updateModelData({
                                 model: "people",
                                 pk: person.id,
                                 name: "country",
                                 value: e.target.value,
                              }).then((response) => refresh())
                           }
                           onKeyPress={handleKeyPress}
                        />
                        <TextField
                           variant='outlined'
                           margin='none'
                           fullWidth
                           label='Date of Birth'
                           defaultValue={person.dob}
                           onBlur={(e) =>
                              updateModelData({
                                 model: "people",
                                 pk: person.id,
                                 name: "dob",
                                 value: e.target.value,
                              }).then((response) => refresh())
                           }
                           onKeyPress={handleKeyPress}
                        />
                     </Stack>
                  </Grid>
               </Grid>
            </FadeIn>
         )}

         {!editMode && (
            <FadeIn>
               <Grid container>
                  <Grid item xs={12}>
                     <Typography variant='h2' component='div'>
                        {person.first} {person.last}
                     </Typography>
                  </Grid>
                  <Grid item xs={12}>
                     <Chip
                        label='Contractor Type Here'
                        color='error'
                        sx={{
                           fontSize: ".8rem",
                           mb: 4,
                        }}
                     />
                  </Grid>
                  <Grid item xs={6}>
                     <Stack spacing={1}>
                        <Typography variant='h5' component='div'>
                           {person.location}, {person.state}
                        </Typography>
                        <Box>
                           {person.phone}
                           <br />
                           {person.email}
                        </Box>
                     </Stack>
                  </Grid>
                  <Grid item xs={6}>
                     <Stack spacing={1}>
                        <Typography variant='h5' component='div'>
                           {person.address}
                           <br />
                           {person.city}, {person.state} {person.zip}
                           <br />
                           {person.country}
                        </Typography>
                        <Typography variant='h5' component='div'>
                           <img src={BirthdayIcon} style={{ marginRight: 5 }} />{" "}
                           {person.dob}
                        </Typography>
                     </Stack>
                  </Grid>
               </Grid>
            </FadeIn>
         )}
      </>
   );
}
