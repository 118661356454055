import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";

export default function ChipWrapper({ label, size }) {
   const theme = useTheme();

   return (
      <Chip
         label={label}
         size='small'
         sx={{
            backgroundColor:
               label &&
               theme.palette[label.toLowerCase().replace(/\s/g, "")].main,
            fontSize: size === "large" ? ".8rem" : ".6rem",
            mr: 1,
            my: 1,
         }}
      />
   );
}
