import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import dateFormat from "dateformat";

// COMPONENTS
import FadeIn from "components/wrappers/FadeIn";
import Schedule from "components/schedule/Schedule";
import ScheduleOverview from "components/schedule/ScheduleOverview";
import AddDate from "components/project/AddDate";

//HOOKS
import useGlobal from "hooks/useGlobal";

// MUI
import { Box, Typography, Tabs, Tab } from "@mui/material";

function TabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role='tabpanel'
         hidden={value !== index}
         id={`schedule-tabpanel-${index}`}
         aria-labelledby={`schedule-tab-${index}`}
         {...other}
      >
         {value === index && (
            <FadeIn>
               <Box>
                  <Typography>{children}</Typography>
               </Box>
            </FadeIn>
         )}
      </div>
   );
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
};

function a11yProps(index) {
   return {
      id: `schedule-tab-${index}`,
      "aria-controls": `schedule-tabpanel-${index}`,
   };
}

export default function ScheduleTabs({ projectId, parent = "" }) {
   const model = "projectdates";

   const [
      projectdate,
      all,
      { getAll, get, updateModelData, add, remove, clearParent, find },
   ] = useGlobal();

   const [tab, setTab] = useState(0);
   const handleTabChange = (event, newValue) => {
      setTab(newValue);
   };

   const handleAddDate = (date) => {
      add("projectdates", projectId, "date", date).then(() => {
         handleRefresh();
      });
   };

   useEffect(() => {
      if (projectId) {
         getAll(model, projectId);
      }
   }, []);

   useEffect(() => {
      // console.log(all);
   }, [all]);

   const handleRefresh = () => {
      // console.log("refresh!", projectId, model);
      if (projectId) {
         getAll(model, projectId);
      }
   };

   return (
      <>
         <Box sx={{ borderBottom: 1, borderColor: "#414755", px: 4 }}>
            <Tabs value={tab} onChange={handleTabChange}>
               {all.projectdates &&
                  all.projectdates.map(
                     (row, i) =>
                        row.date && (
                           <Tab
                              key={"tab-" + i + "-" + row.description}
                              label={dateFormat(
                                 row.date + "T00:00:00.000",
                                 "m/dd"
                              )}
                              {...a11yProps(i)}
                           />
                        )
                  )}
            </Tabs>
            {all.projectdates && all.projectdates.length === 0 && (
               <Box sx={{ width: "100%", mb: 2 }}>
                  <AddDate callback={handleAddDate} />
               </Box>
            )}
         </Box>
         {all.projectdates &&
            all.projectdates.map((row, i) => (
               <>
                  {row.date && (
                     <TabPanel value={tab} index={i}>
                        {row.description && (
                           <Typography
                              variant='h4'
                              component='div'
                              sx={{ my: 3, mx: 4 }}
                           >
                              {row.description}
                           </Typography>
                        )}

                        {parent === "overview" ? (
                           <ScheduleOverview
                              projectId={projectId}
                              key={"scheduleTab" + i}
                              pk={row.id}
                           />
                        ) : (
                           <Schedule
                              projectId={projectId}
                              key={"scheduleTab" + i}
                              pk={row.id}
                              allProjectDates={all.projectdates}
                              changeTab={setTab}
                              scheduleTitle={row.description}
                              scheduleDate={row.date}
                              refresh={handleRefresh}
                           />
                        )}
                     </TabPanel>
                  )}
               </>
            ))}
      </>
   );
}
